/**
 * 模块名称: 公司折扣配置
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Button,
  message,
  Icon,
  Tooltip,
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
// import CompanyList from '@/components/CompanyList'
import Export from '@/components/Export'
import Auth from '@/components/AuthMiddleware'
import { parseSearch } from '@/utils'
import { Link } from 'react-router-dom'
import Import from '@/components/Import'

const { Column } = Table
let pageSize = 10
let currentPage = 1
let Key = ''

let filter = {
  pro_doc_mec: '',
}

let selectedIds = ''

const Company = (props) => {
  const { match, location, history } = props.props
  const [visibleFile, setVisibleFile] = useState(false)
  const productsRef = useRef()
  const ImportRef = useRef()
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [authList, setAuthList] = useState([])
  const [showExport, setShowExport] = useState(false)
  const [selectedCount, setSelectedCount] = useState(0)

  const search = parseSearch(location.search)
  Key = search.activeKey ? +search.activeKey : '2'

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    filter = {
      pro_doc_mec: '',
    }
    api.getPageAuth().then(list => setAuthList(list))
    getDiscountCompanyList()
    // console.log("公司折扣配置")
  }, [Key])

  const getDiscountCompanyList = () => {
    setLoading(true)
    api.getDiscountCompanyList({
      pro_doc_mec: filter.pro_doc_mec,
      limit: pageSize,
      page: currentPage
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}&activeKey=${Key}`)
    getDiscountCompanyList()
  }

  const departInp = useRef(null)

  const onTopSearch = () => {
    filter.pro_doc_mec = departInp.current.value
    history.replace(match.path + '?activeKey=' + 2)
    currentPage = 1
    getDiscountCompanyList()
  }

  const onTopReset = () => {
    filter.pro_doc_mec = ''
    departInp.current.value = ''
    currentPage = 1
    history.replace(match.path + '?activeKey=' + 2)
    getDiscountCompanyList()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedCount(selectedRowKeys.length)
      selectedIds = selectedRowKeys
    }
  }

  const onExportFile = () => {
    setShowExport(true)
  }

  //导入
  const onImportFile = () => {
    setVisibleFile(true)
    ImportRef.current.onRemoved()
  }

  const onCloseImport = () => {
    setVisibleFile(false)
  }

  const onSearch = (fileList) => {
    if (fileList.length > 0) {
      if (fileList[0].status !== "removed") {
        api.setDiscountCompanyImport({
          url: fileList[0].url
        }).then(data => {
          message.success('导入成功，请到导入列表查看。')
          ImportRef.current.onLoading()
          setVisibleFile(false)
          window.location.reload()
        }).catch(data => {
          ImportRef.current.onLoading()
        })
      } else {
        message.error('请导入模板')
      }
    } else {
      message.error('请导入模板')
    }
  }

  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onTopSearch()
    }
  }

  return (
    <>
      <div className="businessStySea">
        <div className="filter-wrap" style={{paddingBottom: 31}}>
          <h4 className="title">搜索：</h4>
          <div className="filter-input">
            <label htmlFor=""></label>
            <input className="ant-input" placeholder="项目/产品/供应商" ref={departInp} type="text" onKeyUp={onKeyup} />
          </div>
          <BtnGroup cancelName="重置" confirmName="筛选" onConfirm={onTopSearch} onCancel={onTopReset} />
        </div>
        <div className="businessStyLine"></div>
      </div>
      <>
        <div className="businessSty">
          <Auth auths={authList} code="operate">
            <Button type="primary" icon="upload" onClick={onExportFile}>导出</Button>
            <Button type="primary" icon="download" onClick={onImportFile}>导入</Button>
          </Auth>
        </div>
        <Alert className="corp-count" message={
          <>
            <span>已选择 <span style={{ color: '#168FFF' }}>{selectedCount}</span> 项</span>
            <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
          </>
        } type="info" showIcon />
        {/* <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon /> */}
        <Table
          size="middle"
          dataSource={list}
          rowKey="id"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          // scroll={{ x: 1800 }}
          onChange={onChangeTable}
          rowSelection={rowSelection}
        >
          <Column title="序号" dataIndex="id" />
          <Column title="供应商" dataIndex="supplier" />
          <Column title="供应商编码" dataIndex="supplier_no" />
          <Column title="关联项目" dataIndex="projectNames" />
          <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
            <>
              <Auth auths={authList} code="operate">
              <Link to={`${match.path}/detail?supplier=${record.supplier}&supplierId=${record.supplierId}`}>
                <Tooltip title="修改" placement="bottom">
                    <Icon className="operate-icon" style={{ color: '#168FFF' }} type="edit" />
                </Tooltip>
                {/* <Button type="primary" icon="plus" title="发布" style={{ marginLeft: '15px' }}>发布</Button> */}
              </Link>
              </Auth>
            </>
          )} />
        </Table>
        <Export
          show={showExport}
          onCancel={() => {
            setShowExport(false)
            productsRef.current.changeVal()
          }}
          onConfirm={() => { }}
          tplUrl="getDiscountTwoExportTpl"
          fieldsUrl="getDiscountTwoTplItems"
          saveUrl="saveDiscountTwoTpl"
          exportUrl="exportDiscountTwoExport"
          method="companyList"
          parame={{ ExportType: 'discount.companyList', id: selectedIds, ...filter }}
          cRef={productsRef}
        />
        <Import
          onSearch={onSearch}
          visibleFile={visibleFile}
          onCloseImport={onCloseImport}
          ImportTemplate="/api/File/DownTemplate?url=/templates/company.xlsx&name=公司折扣配置导入模板"
          ImportRef={ImportRef}
        />
      </>
    </>
  )
}

export default Form.create()(Company)