/**
 * 模块名称: 项目设置
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Drawer,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Spin,
  Checkbox
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import CompanyList from '@/components/CompanyList'
import Export from '@/components/Export'
import Auth from '@/components/AuthMiddleware'
import { parseSearch } from '@/utils'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1

let filter = {
  name: '',
  companyId: '',
}

let selectedIds = ''

const Projectsetting = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form

  const productsRef = useRef()

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [formLoading, setformLoading] = useState(false)
  const [authList, setAuthList] = useState([])
  const [batchDepartment, setBatchDepartment] = useState([])  //部门
  const [supplier, setSupplier] = useState([]) //供应商
  const [oneVal, setoneVal] = useState('')
  const [twoVal, setTwoVal] = useState ('')
  const [itemVal, setItemVal] = useState ({})
  const [showExport, setShowExport] = useState(false)
  const [selectedCount, setSelectedCount] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [departmentNames, setDepartmentNames] = useState([])

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    filter = {
      keyword: '',
      companyId: ''
    }
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  useEffect(() => {
    if (itemVal.departmentIds !== '') {
      setFieldsValue({
        departmentIds: itemVal.departmentIds !== '' ? itemVal.departmentIds && itemVal.departmentIds.split(',').map(Number) : undefined,
      })
      setDepartmentNames(itemVal.departmentNames !== '' ? itemVal.departmentNames && itemVal.departmentNames.split(',') : undefined,)
    }
  }, [supplier, batchDepartment, itemVal])

  const getSysProjectSettingList = () => {
    setLoading(true)
    api.getSysProjectSettingList({
      companyId: filter.companyId,
      keyword: filter.keyword,
      limit: pageSize,
      page: currentPage
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  // 部门
  const onDepartment = () => {
    api.getSelectDepartmentList({
      departmentType:1,
      companyId: filter.companyId,
      limit: global.paramsLimit
    }).then(data => {
      setBatchDepartment(data.list)

    }).catch(() => setLoading(false))
  }

  // 提交添加
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if (twoVal === '' || oneVal === '') {
          message.error('请填写续费业绩分成比例')
          return
        }

        let params = {
          id: itemVal.id,
          companyId: filter.companyId,
          sysProjectId: itemVal.sysProjectId,
          departmentIds: vals.departmentIds && vals.departmentIds.join(','),
          departmentNames: departmentNames && departmentNames.join(','),
          sellerPercent: oneVal,
          operatorPercent: twoVal,
          supplierIds: vals.supplierIds && vals.supplierIds.join(',')
        }

        setSaveLoading(true)
        api.saveSysProjectSetting(params)
          .then(() => {
            message.success('设置成功')
            setDepartmentNames('')
            setSaveLoading(false)
            setVisible(false)
            getSysProjectSettingList()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  const onSetting = (item) => {
    setVisible(true)
    setformLoading(true)
    onDepartment()
    setItemVal(item)
    setoneVal(item.sellerPercent)
    setTwoVal(item.operatorPercent)

    api.getSupplierListWithProject({
      sysProjectId: item.sysProjectId,
      companyId: filter.companyId,
      limit: global.paramsLimit
    }).then(data => {
      setSupplier(data.list)
      if (data.selected !== '' ) {
        setFieldsValue({
          supplierIds: data.selected && data.selected.split(',').map(Number)
        })
      }
      setformLoading(false)
    }).catch(() => {
      setformLoading(false)
    })
  }

  const onExportFile = () => {
    setShowExport(true)
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getSysProjectSettingList()
  }

  const onChangeCorp = (data) => {
    setSelectedCount(0)
    setSelectedRowKeys([])
    filter.companyId = data.id
    history.replace(match.path)
    currentPage = 1
    getSysProjectSettingList()
  }

  const departInp = useRef(null)

  const onSearch = () => {
    filter.keyword = departInp.current.value
    history.replace(match.path)
    currentPage = 1
    getSysProjectSettingList()
  }

  const onReset = () => {
    filter.keyword = ''
    departInp.current.value = ''
    history.replace(match.path)
    currentPage = 1
    getSysProjectSettingList()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedCount(selectedRowKeys.length)
      setSelectedRowKeys(selectedRowKeys)
      selectedIds = selectedRowKeys
    },
    selectedRowKeys: selectedRowKeys
  }

  const onOneValChange = (e) => {
    setoneVal(e.target.value)
    const reg = /^([1-9]\d*|[0]{1,1})$/
    if(e.target.value !== '') {
      if (!reg.test(e.target.value)) {
        message.error('仅能输入数字')
        setoneVal(oneVal)
        setTwoVal(twoVal)
        return
      } else {
        setTwoVal(100 - parseInt(e.target.value))
      }
    }
  }

  const onTwoValChange = (e) => {
    setTwoVal(e.target.value)
    const reg = /^([1-9]\d*|[0]{1,1})$/
    if(e.target.value !== '') {
      if (!reg.test(e.target.value)) {
        message.error('仅能输入数字')
        setoneVal(oneVal)
        setTwoVal(twoVal)
        return
      } else {
        setoneVal(100 - parseInt(e.target.value))
      }
    }
  }

  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  //部门名称
  const getDepartment = (value,option) => {
    let arr = []
    option && option.map((item) => {
      arr.push(item.props.children)
    })
    setDepartmentNames(arr)
  }

  const onCancelSave = () => {
    setVisible(false)
    setDepartmentNames('')
  }

  return (
    <>
      <CompanyList onChange={onChangeCorp} radio />
      <div className="filter-wrap" style={{paddingBottom: 10}}>
        <h4 className="title">搜索：</h4>
        <div className="filter-input">
          <label htmlFor=""></label>
          <input className="ant-input" placeholder="项目/项目负责人/项目负责部门" ref={departInp} type="text" onKeyUp={onKeyup} />
        </div>
        <BtnGroup cancelName="重置" confirmName="筛选" onConfirm={onSearch} onCancel={onReset} />
      </div>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="operate">
          <Button type="primary" icon="upload" onClick={onExportFile}>导出</Button>
        </Auth>
      </div>
      <Alert className="corp-count" message={
        <>
          <span>已选择 <span style={{ color: '#168FFF' }}>{selectedCount}</span> 项</span>
          <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
        </>
      } type="info" showIcon />
      {/* <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon /> */}
      <Table
        size="middle"
        dataSource={list}
        rowKey="sysProjectId"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1400 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="项目" dataIndex="sysProjectName" fixed="left" width={200} />
        <Column title="ID" dataIndex="sysProjectId" />
        <Column title="续费业绩分成比例" render={(text, record) => `销售${text.sellerPercent}% 运营${text.operatorPercent}%`} />
        <Column title="项目负责人" dataIndex="sysProjectPrincipalInfo.staffName" />
        <Column title="项目负责部门" dataIndex="departmentNames" />
        <Column title="项目供应商" render={(text, record) => `${text.supplierNumber}个`} />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            {record.operate ? <a onClick={() => onSetting(record)}>设置</a> : null}
            {/* <Auth auths={authList} code="operate">
              <a onClick={() => onSetting(record)}>设置</a>
            </Auth> */}
          </>
        )} />
      </Table>
      <Drawer
        title="项目设置"
        width={640}
        onClose={() => setVisible(false)}
        visible={visible}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>
          <Form onSubmit={formSubmit} className="setIndustry">
            <div className="topInput">
              <span style={{ marginRight: 20, marginTop: 10, color: 'red', fontWeight: '700' }}>续费业绩分成比例： </span>
              <span style={{ marginTop: 10 }}>销售</span>
              <FormItem label="">
                {/* {getFieldDecorator('sellerPercent', {
                  rules: [{ required: true, message: '请输入' },
                  {
                    pattern: /^([1-9]\d*|[0]{1,1})$/, message: '仅能输入数字'
                  }]
                })( */}
                  <Input value={oneVal} onChange={onOneValChange} placeholder="" />
                {/* )} */}
              </FormItem>
              <span style={{ marginLeft: 10, marginTop: 10 }}>-</span>
              <FormItem label="">
                {/* {getFieldDecorator('operatorPercent', {
                  rules: [{ required: true, message: '请输入' },
                  {
                    pattern: /^([1-9]\d*|[0]{1,1})$/, message: '仅能输入数字'
                  }]
                })( */}
                  <Input value={twoVal} onChange={onTwoValChange} placeholder="" />
                {/* )} */}
              </FormItem>
              <span style={{ marginLeft: 10, marginTop: 10 }}>运营</span>
            </div>
            <div className="departmentSty">
              <FormItem label="项目负责部门" labelCol={{ span: 5 }}>
                {getFieldDecorator('departmentIds')(
                  <Select placeholder="请选择"
                    mode="multiple"
                    style={{ width: '100%' }}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value, option) => getDepartment(value, option)}
                  >
                    {batchDepartment && batchDepartment.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>
                )}
              </FormItem>
            </div>
            <div className="departmentSty">
              <FormItem label="项目供应商" labelCol={{ span: 4 }}>
                {getFieldDecorator('supplierIds')(
                  <Select
                     placeholder="请选择"
                     mode="multiple"
                     style={{ width: '100%' }}
                     filterOption={(input, option) =>
                       option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                     }
                   >
                    {supplier && supplier.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>
                  // <Checkbox.Group style={{ width: '100%', display: 'flex', flexWrap:'wrap' }}>
                  //   {supplier.map((item, index) => (  
                  //     <Checkbox key={index} value={item.id.toString()} style={{ marginLeft: 20, marginBottom: 6 }} >{item.name}</Checkbox>
                  //   ))}
                  // </Checkbox.Group>
                )}
              </FormItem>
            </div>
            <div style={{height:60}}></div>
            <div className="setShortCloseBtn">
              <BtnGroup onCancel={onCancelSave} loading={saveLoading} />
            </div>
          </Form>
        </Spin>
      </Drawer>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        onConfirm={() => {}}
        tplUrl="getCompanyProjectSettingExportTpl"
        fieldsUrl="getCompanyProjectSettingTplItems"
        saveUrl="getCompanyProjectSettingSaveExportTpls"
        exportUrl="exportCompanyProjectSetting"
        method="export"
        parame={{ ExportType: 'CompanyProjectSettingCtl.Export', id: selectedIds, ...filter }}
        cRef={productsRef}
      />
    </>
  )
}

export default Form.create()(Projectsetting)