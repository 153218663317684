/**
 * 模块名称: 订单字段设置
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Checkbox,
  Button,
  message
} from 'antd'
import { parseSearch } from '@/utils'

const { Column } = Table

const SetTheField = (props) => {
  const { history } = props
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [list, setList] = useState([])
  const [titleDate, setTitleDate] = useState({})
  const search = parseSearch(props.location.search)

  useEffect(() => {
    getBizOrderFields()
  }, [])


  const getBizOrderFields = () => {
    setLoading(true)
    api.getBizOrderFields({ bizId: search.id }).then(data => {
      let tmp = {}
      let datas = data.list
      for (let i = 0; i < datas.length; i++) {
        tmp[datas[i].sysOrderFieldInfo.id] = {}
        tmp[datas[i].sysOrderFieldInfo.id]["isChoosed"] = datas[i].isChoosed === 1 ? true : false
        tmp[datas[i].sysOrderFieldInfo.id]["isMustRequired"] = datas[i].isMustRequired === 1 ? true : false
      }
      setData(tmp)
      setList(data.list)
      setTitleDate(data)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const onChoose = (id) => {
    let tmp = { ...data }
    tmp[id]["isChoosed"] = !tmp[id]["isChoosed"]
    if (!tmp[id]["isChoosed"]) {
      tmp[id]["isMustRequired"] = false
    }
    setData(tmp)
  }

  const onMandatory = (id) => {
    let tmp = { ...data }
    tmp[id]["isMustRequired"] = !tmp[id]["isMustRequired"]
    if (tmp[id]["isMustRequired"]) {
      tmp[id]["isChoosed"] = true
    }
    setData(tmp)
  }

  const onBizOrderClick = () => {
    let resArry = []
    for (var key in data) {
      let map = {}
      map["sysOrderFieldId"] = +key
      map["isChoosed"] = data[key]["isChoosed"] ? 1 : 0
      map["isMustRequired"] = data[key]["isMustRequired"] ? 1 : 0
      resArry.push(map)
    }
    updateSysBizFields(resArry)
  }
  const updateSysBizFields = (resArry) => {
    api.updateSysBizFields({ bizId: search.id, data: resArry })
      .then(res => {
        getBizOrderFields()
        message.success("保存成功")
        history.push('/config/projectMgt', { ppid: search.ppid, pid: search.pid })
      })
      .catch()
  }

  const onDeselect = () => {
    // history.goBack()
    history.push('/config/projectMgt', { ppid: search.ppid, pid: search.pid })
  }

  return (
    <>
      <div className="bizOrderFields">
        <h3 style={{ marginTop: 10 }}>订单资料字段设置</h3>
        <div>{`${titleDate.projectName}/${titleDate.productName}/${titleDate.bussinessName}`}</div>
      </div>
      <div className="borderLine"></div>
      <Table
        dataSource={list}
        rowKey="id"
        pagination={false}
        className="bizOrder-table"
        loading={loading}
      >
        <Column title="" render={(text, record) => (
          <>
            {
              <Checkbox checked={data[record.sysOrderFieldInfo.id]["isChoosed"]} onChange={() => onChoose(record.sysOrderFieldInfo.id)}>选择</Checkbox>
            }
            {
              <Checkbox checked={data[record.sysOrderFieldInfo.id]["isMustRequired"]} onChange={() => onMandatory(record.sysOrderFieldInfo.id)}>必填</Checkbox>
            }
          </>
        )} />

        <Column title="字段ID" dataIndex="sysOrderFieldInfo.id" />
        <Column title="字段中文名" dataIndex="sysOrderFieldInfo.cnName" />
        <Column title="字段名" dataIndex="sysOrderFieldInfo.enName" />
        <Column title="字段排序" dataIndex="sysOrderFieldInfo.sort" />
      </Table>
      <div className="bizOrder-btn">

        <Button onClick={onDeselect}>取消</Button>

        <Button type="primary" style={{ marginRight: 260, marginLeft: 30 }} onClick={onBizOrderClick}>保存</Button>
      </div>
    </>
  )
}

export default Form.create()(SetTheField)