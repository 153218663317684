/**
 * 模块名称: 订单字段管理
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Button,
  Input,
  Popconfirm,
  Icon,
  message,
  Modal
} from 'antd'

const { Column } = Table
const FormItem = Form.Item
const EditableContext = React.createContext()

const SetTheField = (props) => {
  const { history } = props
  const { getFieldDecorator, validateFields } = props.form

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [editingFieldKey, setEditingFieldKey] = useState('')
  const [fieldEditVal, setFieldEditVal] = useState('') // 字段名
  const [sortVal, setSortVal] = useState('') // 排序值
  const [fieldModal, setFieldModal] = useState(false) // 新增字段
  const [saveLoading, setSaveLoading] = useState(false)


  useEffect(() => {
    getSysOrderFieldsList()
  }, [])

  const getSysOrderFieldsList = () => {
    setLoading(true)
    api.getSysOrderFieldsList({ limit: global.paramsLimit }).then(data => {
      setList(data.list)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const onGoback = () => {
    history.goBack()
  }

  const isFieldEditing = record => {
    // console.log("editingFieldKey", record)
    return record.id === editingFieldKey
  }
  const onChangeFieldVal = (e) => {
    e.persist()
    setFieldEditVal(e.target.value)
  }
  const onChangeSortVal = (e) => {
    e.persist()
    setSortVal(e.target.value)
  }

  const editField = (record) => {
    setFieldEditVal(record.cnName)
    setSortVal(record.sort)
    setEditingFieldKey(record.id)
  }

  const editFieldCancel = (id) => {
    setEditingFieldKey('')
  }

  const updateSysOrderFieldsIsDel = (id) => {
    api.updateSysOrderFieldsIsDel({ id, isDel: 1 }, true).then(() => {
      message.success('删除成功')
      getSysOrderFieldsList()
    })
  }

  const editFieldSave = (val) => {
    let cnReg = /^[\u4e00-\u9fa5]{0,}$/
    let sortReg = /^([1-9]\d*|[0]{1,1})$/
    if (fieldEditVal == '' || !cnReg.test(fieldEditVal)) {
      message.warning('字段中文名不能为空且仅能输入中文')
      return
    }
    if (!sortReg.test(sortVal)) {
      message.warning('字段排序仅能输入0和正整数')
      return
    }
    api.saveSysOrderFields({
      id: val.id,
      enName: val.enName,
      cnName: fieldEditVal,
      sort: sortVal
    }).then(res => {
      message.success('保存成功')
      setEditingFieldKey('')
      getSysOrderFieldsList()
    })
  }

  // 新增字段
  const addFields = () => {
    setFieldModal(true)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        // console.log("vals", vals)
        // if (editId) {
        //   vals.id = editId
        // }
        setSaveLoading(true)
        api.saveSysOrderFields({
          id: '',
          enName: vals.enName,
          cnName: vals.cnName,
          sort: vals.sort
        })
          .then(() => {
            setSaveLoading(false)
            setFieldModal(false)
            message.success('添加成功')
            getSysOrderFieldsList()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }
  return (
    <>
      <div>
        <h3 style={{ marginTop: 10 }}>订单字段管理</h3>
      </div>
      <div className="add-projectMgt">
        {/* <Auth auths={authList}> */}
        <Button type="primary" icon="plus" className="add-projectMgt-btn" onClick={addFields}>新增字段</Button>
        {/* </Auth> */}
      </div>
      <EditableContext.Provider>
        <Table
          dataSource={list}
          rowKey="id"
          loading={loading}
          pagination={false}
        >
          <Column title="字段ID" dataIndex="id" width={'15%'} />
          <Column title="字段中文名"
            width={'35%'}
            render={(text, record) =>
              <>
                {isFieldEditing(record) ?
                  <div>
                    <Input
                      onPressEnter={() => editFieldSave(record.id)}
                      defaultValue={text.cnName}
                      onChange={onChangeFieldVal}
                      onPressEnter={() => {return}}
                    />
                  </div>
                  :
                  <div style={{ lineHeight: '32px' }}>{text.cnName}</div>}
              </>
            }
          />
          <Column title="字段名" dataIndex="enName" />
          <Column title="字段排序"
            width={'10%'}
            render={(text, record) =>
              <>
                {isFieldEditing(record) ?

                  <div>
                    <Input
                      onPressEnter={() => editFieldSave(record.id)}
                      defaultValue={text.sort}
                      onChange={onChangeSortVal}
                      onPressEnter={() => {return}}
                    />
                  </div>
                  :
                  <div style={{ lineHeight: '32px' }}>{text.sort}</div>}
              </>
            }
          />
          <Column title="操作"
            render={(text, record) => {
              const editable = isFieldEditing(record)
              return editable ? (
                <span>
                  <Popconfirm title="确定要取消编辑吗？" onConfirm={() => editFieldCancel(record.id)}>
                    <Icon className="operate-icon" type="close" style={{ color: '#F35D58' }} />
                  </Popconfirm>
                  <EditableContext.Consumer>
                    {() => (
                      <a
                        onClick={() => editFieldSave(record)}
                        style={{ marginRight: 8 }}
                      >
                        <Icon className="operate-icon" type="check" style={{ color: '#3A9F33' }} />
                      </a>
                    )}
                  </EditableContext.Consumer>
                </span>
              ) : (
                  <>
                    <a disabled={editingFieldKey !== ''} onClick={() => editField(record)}>
                      <Icon className="operate-icon" type="edit" />
                    </a>
                    <a disabled={editingFieldKey !== ''}>
                      <Popconfirm title="确定要删除该类别吗？" onConfirm={() => updateSysOrderFieldsIsDel(record.id)}>
                        {
                          editingFieldKey !== '' ?
                            <Icon className="operate-icon" type="delete" style={{ color: '#c8c8c8' }} />
                            :
                            <Icon className="operate-icon" type="delete" style={{ color: '#F35D58' }} />
                        }
                      </Popconfirm>
                    </a>
                  </>
                )
            }}
          />
        </Table>
      </EditableContext.Provider>
      <Modal
        title="新增字段"
        visible={fieldModal}
        onOk={formSubmit}
        onCancel={() => { setFieldModal(false) }}
        destroyOnClose={true}
      >
        <Form {...formItemLayout}>
          <FormItem label="字段中文名称">
            {getFieldDecorator('cnName', {
              rules: [{ required: true, message: '请输入字段名称' },
              {
                pattern: /^[\u4e00-\u9fa5]{0,}$/, message: '仅能输入中文'
              }]
            })(
              <Input placeholder="字段名称" />
            )}
          </FormItem>
          <FormItem label="字段英文名称">
            {getFieldDecorator('enName', {
              rules: [{ required: true, message: '请输入字段名称' },
              {
                pattern: /^[A-Za-z]+$/, message: '仅能输入英文字母，区分大小写'
              }]
            })(
              <Input placeholder="字段名称" />
            )}
          </FormItem>
          <FormItem label="字段排序">
            {getFieldDecorator('sort', {
              rules: [{ required: true, message: '请输入字段排序' },
              {
                pattern: /^([1-9]\d*|[0]{1,1})$/, message: '仅能输入0和正整数'
              }]
            })(
              <Input placeholder="字段排序" />
            )}
          </FormItem>
        </Form>
      </Modal>
      <div className="bizOrder-btn">
        <Button onClick={onGoback} style={{ marginRight: 260 }}>返回</Button>
      </div>
    </>
  )
}

export default Form.create()(SetTheField)