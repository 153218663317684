/**
 * 模块名称: 委托书列表
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Drawer,
  Spin,
  Row,
  Col,
  Modal,
  Popconfirm,
  Radio,
  Upload
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import Export from '@/components/Export'
import BtnGroup from '@/components/BtnGroup'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { Dragger } = Upload
let editId = '' //编辑使用
let pageSize = 10
let currentPage = 1

let filter = {
  companyId: ''
}

let selectedIds = ''
let params = {}
let relationType = 0

const Authorization = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, setFieldsValue, validateFields, getFieldValue, resetFields } = props.form
  const productsRef = useRef()
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [authList, setAuthList] = useState([])
  const [selectedCount, setSelectedCount] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [expand, setExpand] = useState(false)
  const [relationTypeList, setRelationTypeList] = useState([])
  const [showExport, setShowExport] = useState(false)
  const search = parseSearch(location.search)
  const [statusVal, setStatusVal] = useState(undefined)
  const [filterContent, setFilterContent] = useState([])
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [visible, setVisible] = useState(false)
  const [drawerTitle, setDrawerTitle] = useState('添加委托书')
  // const [checked, setChecked] = useState(0)
  // const [fileList1, setFileList1] = useState([])
  const [fileList2, setFileList2] = useState([])
  const [visibleEdit, setVisibleEdit] = useState(false)
  // const [visibledraggerRef1, setVisibledraggerRef1] = useState(false)
  const [visibledraggerRef2, setVisibledraggerRef2] = useState(false)
  const [formLoading, setformLoading] = useState(false)//eslint-disable-line
  const [saveLoading, setSaveLoading] = useState(false)
  const [companyType, setCompanyType] = useState([])
  // const draggerRef1 = useRef(null)
  const draggerRef2 = useRef(null)
  const [authorizaTionData, setAuthorizaTionData] = useState({})
  const [statusValName, setStatusValName] = useState(undefined)
  // const [flag, setFlag] = useState(false)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    filter.authorAccount = ''
    filter.authorName = ''
    filter.no = ''
    filter.relation = ''
    filter.relationId = ''
    filter.relationName = ''
    filter.relationType = ''
    getList()
    getRelationTypeList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    setLoading(true)
    api.getAuthorizationList({ ...filter, limit: pageSize, page: currentPage, type: 2, needAuth:1 })
    .then(data => {
      setLoading(false)
      for(var i = 0; i < data.list.length; i++){
        //data.list[i].operateAuth = ['edit', 'delete']
      }
      setList(data.list)
      setCount(data.count)
    })
    .catch(() => setLoading(false))
  }

  const getRelationTypeList = () => {
    api.getSelectRelationTypeList({ type: "customer_type" }).then(data => {
      setRelationTypeList(data)
    })
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        filter = { ...filter, ...vals }
        // console.log(filter)
        history.replace(match.path)
        currentPage = 1
        getList()
      }
    })
  }
  
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getList()
  }

  const onChangeCorp = (data) => {
    setSelectedCount(0)
    setSelectedRowKeys([])
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedCount(selectedRowKeys.length)
      setSelectedRowKeys(selectedRowKeys)
      selectedIds = selectedRowKeys
    },
    selectedRowKeys: selectedRowKeys
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onReset = () => {
    resetFields()
    filter.authorAccount = ''
    filter.authorName = ''
    filter.no = ''
    filter.relation = ''
    filter.relationId = ''
    filter.relationName = ''
    filter.relationType = ''
    setStatusVal(undefined)
    setStatusValName(undefined)
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onShowExportDialog = () => {
    if (filter.companyId && filter.companyId.length > 0) {
      if (!Array.isArray(filter.companyId)) {
        filter.companyId = filter.companyId.split(',')
      }
    } else {
      filter.companyId = []
    }
    setShowExport(true)
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }

  const HandleCancel = () => {
    setVisible(false) //弹出框内取消
  }
  
  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const onChangeGetFilter = (val) => {
    // val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    // let data = JSON.parse(value)
    let data = filterContent[value].content
    setFieldsValue({
      companyId: data.companyId ? data.companyId : undefined,
      relationId: data.relationId ? data.relationId : undefined,
      relationName: data.relationName ? data.relationName : undefined,
      no: data.no ? data.no : undefined,
      relation: data.relation ? data.relation : undefined,
      authorAccount: data.authorAccount ? data.authorAccount : undefined,
      authorName: data.authorName ? data.authorName : undefined,
      relationType: data.relationType
    })
  }
  
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals).map(item => item == undefined ? '' : item)
        if (values.some(item => item !== '')) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              params[k] = vals[k]
            }
          }
          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  // 添加
  const onAdd = () => {
    editId = 0
    setDrawerTitle('添加委托书')
    setVisibleEdit(true)
    // setVisibledraggerRef1(false)
    setVisibledraggerRef2(false)
    api.getSelectCompanyList({ limit: global.paramsLimit }).then(data => setCompanyType(data.list))

    // fileList1.length = 0
    fileList2.length = 0
    relationType = ''

    let authorizaTionData1 = {
      relationType: '',
      relationId: undefined,
      relationName: undefined,
      companyId: undefined,
      no: undefined,
      client: undefined,
      authorName: undefined,
      authorAccount: undefined,
      relation: undefined,
      // isUpPowerOfAttorney: '',
      // isCopyPowerOfAttorney: '',
      isUpPayAgreement: '',
      isCopyPayAgreement: '',
      remark: undefined
    }
    setAuthorizaTionData(authorizaTionData1)
  }

  //编辑
  const onEdit = (date) => {
    editId = date.id
    setDrawerTitle('编辑委托书')
    setVisibleEdit(true)
    setformLoading(true)
    api.getSelectCompanyList({ limit: global.paramsLimit }).then(data => setCompanyType(data.list))

    api.getAuthorizationDetail({ id: date.id}).then(data => {
      // fileList1.length = 0
      fileList2.length = 0
      relationType = data.relationType
      let authorizaTionData1 = {
        relationType: data.relationType + '',
        relationId: data.relationId ? data.relationId : undefined,
        relationName: data.relationName ? data.relationName : undefined,
        companyId: data.companyId ? data.companyId : undefined,
        no: data.no ? data.no : undefined,
        client: data.client ? data.client : undefined,
        authorName: data.authorName ? data.authorName : undefined,
        authorAccount: data.authorAccount ? data.authorAccount : undefined,
        relation: data.relation ? data.relation : undefined,
        // isUpPowerOfAttorney: data.isUpPowerOfAttorney + '',
        // isCopyPowerOfAttorney: data.isUpPowerOfAttorney == 0 ? '' : data.isCopyPowerOfAttorney + '',
        isUpPayAgreement: data.isUpPayAgreement + '',
        isCopyPayAgreement: data.isUpPayAgreement == 0 ? '' : data.isCopyPayAgreement + '',
        remark: data.remark ? data.remark : undefined
      }
      setAuthorizaTionData(authorizaTionData1)

      // if(data.isUpPowerOfAttorney == 1){
      //   setVisibledraggerRef1(true)
      // }
      // else{
      //   setVisibledraggerRef1(false)
      // }

      if(data.isUpPayAgreement == 1){
        setVisibledraggerRef2(true)
      }
      else{
        setVisibledraggerRef2(false)
      }

      // try{
      //   data.powerOfAttorneyAttachments = JSON.parse(data.powerOfAttorneyAttachments)
      // }
      // catch(e){}
      
      // if (data.powerOfAttorneyAttachments.length) {
      //   data.powerOfAttorneyAttachments.forEach((item, index) => {
      //     fileList1.push({
      //       status: 'done',
      //       uid: index,
      //       name: item.name,
      //       url: item.url
      //     })
      //   })
      //   setFileList1(data.powerOfAttorneyAttachments.map((item, index) => ({...item, uid: index})))
      // }

      try{
        data.payAgreementAttachments = JSON.parse(data.payAgreementAttachments)
      }
      catch(e){}
      if (data.payAgreementAttachments.length) {
        data.payAgreementAttachments.forEach((item, index) => {
          fileList2.push({
            status: 'done',
            uid: index,
            name: item.name,
            url: item.url
          })
        })
        setFileList2(data.payAgreementAttachments.map((item, index) => ({...item, uid: index})))
      }

      //setFieldsValue(authorizaTionData)
      setformLoading(false)
    }).catch(() => setformLoading(false))

  }

  const onDelete = (id) => {
    api.getAuthorizationDelete({
      id: id, isDel: 1
    }).then(res => {
      message.success('删除成功')
      for(let i = 0; i<selectedIds.length; i++){
        if(selectedIds[i] === id){
          selectedIds.splice(i, 1)
          setSelectedCount(selectedIds.length)
          i--
        }
      }
      getList()
    })
  }

  //打开认款详情
  const onJumpRenkuan = (data) => {
    // alert('暂未实现')
    // console.log('data', data.moneyClaimId)
    history.push(`/fund/moneyClaim/detail?id=${data.moneyClaimId}&time=${+new Date()}&sign=${CryptoJS.MD5(data.moneyClaimId+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`)
  }

  // 保存
  const formSubmits = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // let uploadList1 = []
      // for (let i = 0, k = fileList1.length; i < k; i++) {
      //   if (fileList1[i].status != 'removed') {
      //     uploadList1.push({
      //       url: fileList1[i].url,
      //       name: fileList1[i].name
      //     })
      //   }
      // }

      let uploadList2 = []
      for (let i = 0, k = fileList2.length; i < k; i++) {
        if (fileList2[i].status != 'removed') {
          uploadList2.push({
            url: fileList2[i].url,
            name: fileList2[i].name
          })
        }
      }

      if (!err) {
        let params = {
          id: editId,
          no: vals.edit_no,
          relationType: vals.edit_relationType,
          relationId: vals.edit_relationId,
          relationName: authorizaTionData.relationName,
          authorName: vals.edit_authorName,
          authorAccount: vals.edit_authorAccount,
          client: vals.edit_client,
          relation: vals.edit_relation,
          // isUpPowerOfAttorney: vals.edit_isUpPowerOfAttorney,
          // isCopyPowerOfAttorney: vals.edit_isCopyPowerOfAttorney ? vals.edit_isCopyPowerOfAttorney : '',
          // powerOfAttorneyAttachments: uploadList1,
          isUpPayAgreement: vals.edit_isUpPayAgreement,
          isCopyPayAgreement: vals.edit_isCopyPayAgreement ? vals.edit_isCopyPayAgreement : '',
          payAgreementAttachments: uploadList2,
          companyId: vals.edit_companyId,
          remark: vals.edit_remark
        }
        // if(visibledraggerRef1 && !uploadList1.length){
        //   return message.error('请上传委托书附件')
        // }
        // if(visibledraggerRef2 && !uploadList2.length){
        //   return message.error('请上传付款协议附件')
        // }
        setSaveLoading(true)
        api.saveAuthorization(params).then(() => {
          setSaveLoading(false)
          setVisibleEdit(false)
          message.success('操作成功')
          getList()
        })
        .catch(() => setSaveLoading(false))
      }
    })
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    }
  }

  // 文件上传 
	// const uploadFiles1 = upLoadModule({
	// 	fileList: fileList1,
	// 	setFileList: setFileList1,
	// 	draggerRef: draggerRef1,
	// 	accept: '.rar, .zip, .doc, .docx, .PDF, .jpg, .jpeg, .png, .bmp',
	// 	allowSizeType: 2
	// })

  // 文件上传 
	const uploadFiles2 = upLoadModule({
		fileList: fileList2,
		setFileList: setFileList2,
		draggerRef: draggerRef2,
		accept: '.rar, .zip, .doc, .docx, .PDF, .jpg, .jpeg, .png, .bpm',
		allowSizeType: 2
	})

  //radio选择
  const onChooseRadio = (type, val) => {
    if(type == 'weituo'){
      if(val == "1"){
        // console.log(type +':'+ val)
        // setVisibledraggerRef1(true)
      }
      else{
        // console.log(type +':'+ val)
        // setVisibledraggerRef1(false)
        //fileList1.length = 0
      }
    }
    else if(type == 'fukuan'){
      if(val == "1"){
        // console.log(type +':'+ val)
        setVisibledraggerRef2(true)
      }
      else{
        // console.log(type +':'+ val)
        setVisibledraggerRef2(false)
        //fileList2.length = 0
      }
    }
  }

  //选择客户类型
  const onSetRelationType = (e) => {
    relationType = e.target.value
    let edit_relationId = getFieldValue('edit_relationId')

    if(!edit_relationId || relationType === ''){
      return false
    }

    // console.log(relationType)
    // console.log(edit_relationId)
    
    api.getCusTomDetail({ relationType: relationType, relationId: edit_relationId }, true).then(data => {
      setAuthorizaTionData({...authorizaTionData, relationName: data.relationName ? data.relationName : undefined})
    })
  }

  //自动获取客户/代理商名称
  const onChangeRelationId = (e) => {
    // console.log(e.target.value, 654)
    let edit_relationId = e.target.value

    if(!edit_relationId || relationType === ''){
      return false
    }

    // console.log(relationType)
    // console.log(edit_relationId)

    api.getCusTomDetail({ relationType: relationType, relationId: edit_relationId }, true).then(data => {
      let name = data.relationName ? data.relationName : undefined
      setAuthorizaTionData({...authorizaTionData, relationName: name})
      setFieldsValue({'edit_relationName': name})
    })
  }

  const formItemLayoutTwo = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 11 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 13 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayoutTwo} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="OAID/QID" labelCol={{span: 8}}>
                  {getFieldDecorator('relationId', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="客户/代理商名称" labelCol={{span: 10}}>
                  {getFieldDecorator('relationName', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="委托书编号" labelCol={{span: 8}}>
                  {getFieldDecorator('no', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={4}>
                <FormItem label="关系" labelCol={{span: 6}}>
                  {getFieldDecorator('relation', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand?'':'hide'}>
              <Col span={6}>
                <FormItem label="被委托方账号" labelCol={{span: 10}}>
                  {getFieldDecorator('authorAccount', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="被委托方用户名" labelCol={{span: 11}}>
                  {getFieldDecorator('authorName', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="客户类型" labelCol={{span: 8}}>
                  {getFieldDecorator('relationType')(
                    <Select placeholder="请选择">
                      {relationTypeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{cursor: 'pointer', fontSize: '12px', color: '#1890ff'}}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="search-btns" style={{paddingBottom: 10}}>

        <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                style={{ display: 'inline-block', width: '160px' }}
                placeholder="请选择"
                value={statusVal}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
                dropdownMatchSelectWidth={false}
              >
                {
                  filterContent.map((item, index) => 
                    <Option value={index} key={index}>
                      <div className="closeStyBtn">
                        {item.name}
                        {statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                          e.stopPropagation()
                          onSearchDelete(item.id)
                        }} /></span> : null}
                      </div>
                    </Option>
                  )
                }
              </Select>
            </div>
            <Button className="filter-savebtn" style={{ marginLeft: 8, marginRight: 8 }} onClick={onSaveFilterTerm}>保存筛选条件</Button>
          </div>

          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
          <Modal
            title="保存筛选条件"
            visible={visible}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </div>
      </Form>
      <div className="line"></div>
      <div className="operate-wrap">
        <Auth auths={authList} code="operate">
          {/* <Upload {...uploadProps}> */}
            <Button type="primary" icon="plus" onClick={onAdd}>添加</Button>
          {/* </Upload> */}
          <Button type="primary" icon="upload" onClick={onShowExportDialog}>导出</Button>
        </Auth>
      </div>
      <Alert className="count-alert" message={
        <>
          <span>已选择 <span style={{color: '#168FFF'}}>{selectedCount}</span> 项</span>
          <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
        </>
      }  type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{x: 2000}}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="序号" dataIndex="id" width={120} />
        <Column title="OAID/QID" dataIndex="relationId" />
        <Column title="客户/代理商名称" dataIndex="relationName" render={(text, record) =>
          <>
            {
              <Link to={`${match.path}/details?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link>
            }
          </>
        } />
        <Column title="客户类型" dataIndex="relationTypeCn" />
        <Column title="主体" dataIndex="companyName" />
        <Column title="委托书编号" dataIndex="no" />
        <Column title="关系" dataIndex="relation" />
        {/* <Column title="委托书" dataIndex="isUpPowerOfAttorneyCn" /> */}
        <Column title="指定付款协议" dataIndex="isUpPayAgreementCn"  />
        <Column title="审批人" dataIndex="stfName" />
        <Column title="备注" dataIndex="remark" />
        <Column title="认款编号" dataIndex="moneyClaimNo" render={(text, record) => <span style={{color: '#1890ff', cursor: 'pointer'}} onClick={() => onJumpRenkuan(record)}>{text}</span>}  />
        <Column title="记录日期" dataIndex="addTime" />
        <Column title="操作"
          key="set" 
          fixed="right"
          width={100}
          render={(text, record) =>
            <>
              <Auth auths={text.operateAuth} code="edit">
                <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" onClick={() => onEdit(record)} />
              </Auth>
              {' '}
              <Auth auths={text.operateAuth} code="delete">
                <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                  <Icon className="operate-icon"  style={{ color: 'red' }} type="delete" />
                </Popconfirm>
              </Auth>
            </>
          }
        />
      </Table>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}  
        tplUrl="getAuthorizationExportTpl"
        fieldsUrl="getAuthorizationTplItems"
        saveUrl="saveAuthorizationTpl"
        exportUrl="exportAuthorization"
        method="export"
        parame={{ ExportTyp: 'authorization', id: selectedIds, ...filter }}
        type={1}
        cRef={productsRef}
      />

      <Drawer
        title={drawerTitle}
        width={740}
        onClose={() => setVisibleEdit(false)}
        visible={visibleEdit}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>
          {
            visibleEdit ? <Form onSubmit={formSubmits} {...formItemLayout}>
              <Form.Item label="客户类型">
                {getFieldDecorator('edit_relationType', {initialValue: authorizaTionData.relationType, rules: [{ required: true, message: '请选择客户类型'}]})(
                  <Radio.Group onChange={onSetRelationType}>
                    <Radio value="0">直销</Radio>
                    <Radio value="1">代理商</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              <FormItem label="OAID/QID" className="staffadd-FormItem staffadd-required">
              {getFieldDecorator('edit_relationId', {
              initialValue: authorizaTionData.relationId, rules: [{ required: true, message: '请输入OAID/QID' }]
              })(<Input placeholder="请输入" onKeyUp={onChangeRelationId} />)}
              </FormItem>
              <FormItem label="客户/代理商名称" className="staffadd-FormItem staffadd-required">
              {getFieldDecorator('edit_relationName', {
                initialValue: authorizaTionData.relationName, rules: [{ required: true, message: '客户不存在'}]
              })(<Input placeholder="" disabled />)}
              </FormItem>
              <FormItem label="主体" className="staffadd-FormItem staffadd-required">
                {getFieldDecorator('edit_companyId', {
                  initialValue: authorizaTionData.companyId, rules: [{ required: true, message: '请选择主体' }]
                })(
                  <Select placeholder="请选择" style={{ width: 240 }}>
                    {companyType.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
                  </Select>
                )}
              </FormItem>
              <FormItem label="委托书编号" className="staffadd-FormItem staffadd-required">
              {getFieldDecorator('edit_no', {
                initialValue: authorizaTionData.no, rules: [{ required: true, message: '请输入委托书编号' }]
              })(<Input placeholder="请输入" />)}
              </FormItem>
              <FormItem label="委托方" className="staffadd-FormItem staffadd-required">
              {getFieldDecorator('edit_client', {
                initialValue: authorizaTionData.client, rules: [{ required: true, message: '请输入委托方' }]
              })(<Input placeholder="请输入" />)}
              </FormItem>
              <FormItem label="被委托方用户名" className="staffadd-FormItem staffadd-required">
              {getFieldDecorator('edit_authorName', {
                initialValue: authorizaTionData.authorName, rules: [{ required: true, message: '请输入被委托方用户名' }]
              })(<Input placeholder="请输入" />)}
              </FormItem>
              <FormItem label="被委托方账号" className="staffadd-FormItem staffadd-required">
              {getFieldDecorator('edit_authorAccount', {
                initialValue: authorizaTionData.authorAccount, rules: [{ required: true, message: '请输入被委托方帐号' }]
              })(<Input placeholder="请输入" />)}
              </FormItem>
              <FormItem label="关系" className="staffadd-FormItem staffadd-required">
              {getFieldDecorator('edit_relation', {
                initialValue: authorizaTionData.relation, rules: [{ required: true, message: '请输入关系' }]
              })(<Input placeholder="请输入" />)}
              </FormItem>
              {/* <Form.Item label="委托书" >
                {getFieldDecorator('edit_isUpPowerOfAttorney', {
                  initialValue: authorizaTionData.isUpPowerOfAttorney, rules: [{ required: true, message: '请选择是否提交委托书'}]
                })(
                  <Radio.Group>
                    <Radio value="0" onClick={() => onChooseRadio("weituo", "0")} >未提交</Radio>
                    <Radio value="1" onClick={() => onChooseRadio("weituo", "1")} >已提交</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              {visibledraggerRef1 && <Form.Item label="类型">
                {getFieldDecorator('edit_isCopyPowerOfAttorney', {
                  initialValue: authorizaTionData.isCopyPowerOfAttorney, rules: [{ required: true, message: '请选择委托书类型'}]})(
                  <Radio.Group>
                    <Radio value="0">原件</Radio>
                    <Radio value="1">复印件</Radio>
                  </Radio.Group>
                )}
              </Form.Item>} */}
              {/* {visibledraggerRef1 ? 
              <FormItem label={<><span style={{color: '#f5222d'}}>*</span> 附件</>}>
                <div className="dragger-box" ref={draggerRef1}>
                  <Dragger
                    {...uploadFiles1}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                    <p className="ant-upload-hint">
                      支持扩展名：.rar、zip、doc、docx、pdf、jpg、jpeg、 png、bmp 单个文件大小不超过5M，不超过5个附件。
                  </p>
                  </Dragger>
                </div>
              </FormItem>
              : null} */}
              <Form.Item label="指定付款协议" >
                {getFieldDecorator('edit_isUpPayAgreement', {initialValue: authorizaTionData.isUpPayAgreement, rules: [{ required: true, message: '请选择是否提交付款协议'}]})(
                  <Radio.Group>
                    <Radio value="0" onClick={() => onChooseRadio("fukuan", "0")} >未提交</Radio>
                    <Radio value="1" onClick={() => onChooseRadio("fukuan", "1")} >已提交</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              {visibledraggerRef2 && <Form.Item label="类型">
                {getFieldDecorator('edit_isCopyPayAgreement', {initialValue: authorizaTionData.isCopyPayAgreement, rules: [{ required: true, message: '请选择付款协议类型'}]})(
                  <Radio.Group>
                    <Radio value="0">原件</Radio>
                    <Radio value="1">复印件</Radio>
                  </Radio.Group>
                )}
              </Form.Item>}
              {visibledraggerRef2 ? 
              <FormItem label={<>附件</>}>
                <div className="dragger-box" ref={draggerRef2}>
                  <Dragger
                    {...uploadFiles2}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                    <p className="ant-upload-hint">
                      支持扩展名：.rar、zip、doc、docx、PDF、JPG 单个文件大小不超过5M，不超过5个附件。
                  </p>
                  </Dragger>
                </div>
              </FormItem>
              : null}
              <FormItem label="备注">
                {getFieldDecorator('edit_remark', { initialValue: authorizaTionData.remark })(
                  <Input.TextArea placeholder="请输入备注" rows={5}  cols={250} />
                )}
              </FormItem>
              <BtnGroup onCancel={() => setVisibleEdit(false)} loading={saveLoading} />
            </Form> : <div></div>
          }
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(Authorization)