/**
 * 模块名称: 公司折扣配置详情
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Table,
  Form,
  Button,
  Input,
  Select,
  message,
  Icon,
  Popconfirm,
  Spin,
  Tooltip,
  Modal
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option

let discountEditId = '' //折扣id

const CompanyDetail = (props) => {
  const { location } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const search = parseSearch(location.search)
  const [detailData, setDetailData] = useState({})
  const [detailTitle, setDetailTitle] = useState([])
  const [addDiscountModal, setAddDiscountModal] = useState(false)
  const [formLoading, setformLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [choice, setChoice] = useState([]) // 审批人
  const [industryList, setIndustryList] = useState([]) //一级行业
  const [sysProjectId, setSysProjectId] = useState('') // 项目ID
  const [twoIndustry, setTwoIndustry] = useState([]) //二级行业
  const [proSectionFlag, setProSectionFlag] = useState(true)
  const [oneId, setOneId] = useState('')  // 行业id

  useEffect(() => {
    if(search.supplier) {
      getDetail()
    }
  }, [])

  // 详情
  const getDetail = () => {
    api.getDiscountCompanyDetail({ supplier: search.supplier }, true).then(res => {
      setDetailData(res)
      setDetailTitle(res.list)
    })
  }

  // 审批人
  const getApprover = () => {
    return api.getDiscountInternal({})
      .then(data => {
        setChoice(data)
        return data
      }).catch(() => setformLoading(false))
  }

  // 内控人员搜索
  const approvedSelect = (val) => {
    api.getDiscountInternal({ name: val })
      .then(data => {
        setChoice(data)
      }).catch()
  }

  // 一级行业
  const getIndustryList = (sysProjectId) => {
    return api.getSysProjectIndustryList({
      sysProjectId: sysProjectId,
      projectIndustryPid: 0
    })
      .then(data => {
        setIndustryList(data)
        return data
      }).catch(() => setformLoading(false))
  }

  // 编辑获取二级行业
  const onSelectIndustryTwo = (editProject, editVal) => {
    return api.getSysProjectIndustryList({
      sysProjectId: editProject,
      projectIndustryPid: editVal
    }).then(data => {
      setProSectionFlag(false)
      setTwoIndustry(data)
      return data
    })
  }

  // 获取二级行业
  const onSelectIndustry = (val) => {
    setOneId(val)
    setFieldsValue({
      two_industryId: undefined
    })
    api.getSysProjectIndustryList({
      sysProjectId: sysProjectId,
      projectIndustryPid: val
    }).then(data => {
      setTwoIndustry(data)
      setProSectionFlag(false)
    })
  }

  const onEdit = async (data, vid) => {
    resetFields()
    await setAddDiscountModal(true)
    setSysProjectId(vid) // 项目id
    discountEditId = data.id
    setformLoading(true)
    getIndustryList(vid).then(() => {
      onSelectIndustryTwo(vid, data.one_industryId).then(() => {
        getApprover().then(() => {
          setformLoading(false)
          setFieldsValue({
            one_industryId: data.one_industryId,
            two_industryId: data.two_industryId,
            discount: data.discount,
            approvedByIds: data.approvedByIds
          })
        }).catch(() => {
          setformLoading(false)
        })
      })
    })
  }

  const onDelete = (id) => {
    api.getDiscountCompanyDel({ id }, true).then(() => {
      message.success('删除成功')
      getDetail()
    })
  }

  const addDiscount = (id) => {
    setOneId('')
    setAddDiscountModal(true)
    setProSectionFlag(true)
    discountEditId = ''
    resetFields()
    getApprover()
    getIndustryList(id)
    setSysProjectId(id) // 项目id
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if (discountEditId) {
          vals.id = discountEditId
        }
        setSaveLoading(true)
        api.setDiscountCompanyHand({
          id: vals.id,
          supplier: detailData.supplier,
          supplierId: search.supplierId,
          supplier_no: detailData.supplier_no,
          relation_projectId: sysProjectId,
          one_industryId: vals.one_industryId,
          two_industryId: vals.two_industryId,
          discount: vals.discount,
          approvedByIds: vals.approvedByIds
        })
          .then(() => {
            setSaveLoading(false)
            setAddDiscountModal(false)
            message.success('设置成功')
            getDetail()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  // 一级行业筛选
  const oneSearch = (val) => {
    api.getSysProjectIndustryList({
      sysProjectId: sysProjectId,
      projectIndustryPid: 0,
      name: val
    })
      .then(data => {
        setIndustryList(data)
      }).catch()
  }

  // 二级行业筛选
  const twoSearch = (vale) => {
    api.getSysProjectIndustryList({
      sysProjectId: sysProjectId,
      projectIndustryPid: oneId,
      name: vale
    })
      .then(data => {
        setTwoIndustry(data)
      }).catch()
  }

  return (
    <>
      <div className="detailSty">
        <h3>供应商折扣配置详情</h3>
        <div>
          <span style={{ marginRight: 20 }}>{detailData.supplier}</span>
          <span>供应商编码：{detailData.supplier_no}</span>
        </div>
      </div>
      <div className="detailLine"></div>
      <h4>供应商信息</h4>
      <div className="detailLine"></div>
      <div>
        <div className="detailText">{detailData.supplier}</div>
        {/* <div className="detailText">客户</div> */}
        <div className="detailText detailTextTitle">供应商编码：{detailData.supplier_no}</div>
      </div>
      <div>
        {
          detailTitle.length > 0 && detailTitle.map((v, i) => {
            return <div key={i}>
              <div className="infoline"></div>
              <div className="addDiscountCon">
                <div className="detailTitleSty">{v.name}</div>
                <Button type="primary" onClick={() => addDiscount(v.id)}>添加</Button>
              </div>
              <div className="detailLine"></div>
              {
                <Table pagination={false} dataSource={v.list} rowKey="id">
                  <Column title="序号" dataIndex="id" />
                  <Column title="一级行业" dataIndex="one_industry" />
                  <Column title="二级行业" render={(text, record) => (
                    <div>{record.two_industry ? record.two_industry : '--'}</div>
                  )} />
                  <Column title="最低折扣" dataIndex="discount" />
                  <Column title="审批人" dataIndex="approvedByNames" />
                  <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
                    <>
                      {
                        <Tooltip title="编辑" placement="bottom">
                          <Icon className="operate-icon" style={{ color: '#168FFF' }} type="edit" onClick={() => onEdit(record, v.id)} />
                        </Tooltip>
                      }
                      {' '}
                      {
                        <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                          <Tooltip title="删除" placement="bottom">
                            <Icon className="operate-icon" type="delete" style={{ color: 'red' }} />
                          </Tooltip>
                        </Popconfirm>
                      }
                    </>
                  )} />
                </Table>
              }
            </div>
          })
        }
      </div>
      <Modal
        title={discountEditId ? '修改折扣配置' : '新增折扣配置'}
        visible={addDiscountModal}
        onOk={formSubmit}
        onCancel={() => { setAddDiscountModal(false) }}
        destroyOnClose={true}
        confirmLoading={saveLoading}
      >
        <Spin spinning={formLoading}>
          <Form {...formItemLayout}>
            <FormItem label="一级行业">
              {getFieldDecorator('one_industryId', {
                rules: [{ required: true, message: '请选择一级行业' }]
              })(
                <Select
                  placeholder="请选择一级行业"
                  filterOption={false}
                  // mode="multiple"
                  style={{ width: '100%' }}
                  onSelect={onSelectIndustry}
                  onSearch={oneSearch}
                  autoFocus
                  showSearch={true}
                >
                  {industryList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            <FormItem label="二级行业">
              {getFieldDecorator('two_industryId', {
                rules: [{ required: true, message: '请选择二级行业' }]
              })(

                <Select
                  placeholder="请选择二级行业"
                  filterOption={false}
                  // mode="multiple"
                  style={{ width: '100%' }}
                  disabled={proSectionFlag}
                  onSearch={twoSearch}
                  autoFocus
                  showSearch={true}
                >
                  {twoIndustry.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            <FormItem label="最低折扣">
              {getFieldDecorator('discount', {
                rules: [{ required: true, message: '请输入最低折扣' },
                { pattern: /^(0\.\d{4}|1\.0{4})$/, message: '大于等于0小于等于1,精确到小数点后四位,不足四位用0补足' }]
              })(
                <Input placeholder="请输入最低折扣" />
              )}
            </FormItem>
            <FormItem label="审批人">
              {getFieldDecorator('approvedByIds', {
                rules: [{ required: true, message: '请选推送范围' }]
              })(
                <Select
                  placeholder="请选择审批人"
                  // filterOption={true}
                  mode="multiple"
                  style={{ width: '100%' }}
                  autoFocus
                  showSearch={true}
                  // onSearch={approvedSelect}
                  optionFilterProp="children"
                >
                  {choice.map(item => <Option key={item.id} value={item.id}>{item.staffName}</Option>)}
                </Select>
              )}
            </FormItem>
          </Form>
        </Spin>
      </Modal>
    </>
  )
}

export default Form.create()(CompanyDetail)
