/**
 * 模块名称: 业务部门配置
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Drawer,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Popconfirm,
  Spin,
  Tooltip,
  Modal
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import Export from '@/components/Export'
import Import from '@/components/Import'
import Auth from '@/components/AuthMiddleware'
import { parseSearch } from '@/utils'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
let editId = '' //编辑使用
let pageSize = 10
let currentPage = 1
let Key = ''

let filter = {
  pro_doc_mec: '',
}

let selectedIds = ''

const Business = (props) => {
  const { match, location, history } = props.props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const [visibleFile, setVisibleFile] = useState(false)
  const productsRef = useRef()
  const ImportRef = useRef()
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [formLoading, setformLoading] = useState(false)
  const [authList, setAuthList] = useState([])
  const [showExport, setShowExport] = useState(false)
  const [selectedCount, setSelectedCount] = useState(0)
  const [editVisible, seteditVisible] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [selectData, setSelectData] = useState({
    projectArr: [], //项目
    productArr: [], //产品
    proPosition: [], //职位
    isRequest: false
  })
  const [proSection, setProSection] = useState([]) //产品
  const [proSectionFlag, setProSectionFlag] = useState(true)
  const [industryGroups, setIndustryGroups] = useState([]) //行业分组
  const [indGroupFlag, setIndGroupFlag] = useState(true)
  const [businessFlag, setBusinessFlag] = useState(false)
  const [projectName, setProjectName] = useState(undefined)
  const [projectId, setProjectId] = useState(undefined)
  const [promptVisible, setPromptVisible] = useState(false)
  const [orderId, setOrderId] = useState([])
  const [pId, setPid] = useState('')  // 存储项目id
  const [drawerName, setDrawerName] = useState('新增折扣配置')

  const search = parseSearch(location.search)
  Key = search.activeKey ? +search.activeKey : '1'

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    filter = {
      pro_doc_mec: '',
    }
    api.getPageAuth().then(list => setAuthList(list))
    getDiscountBusinessList()
    // console.log("业务部门配置")
  }, [Key])

  const getDiscountBusinessList = () => {
    setLoading(true)
    api.getDiscountBusinessList({
      pro_doc_mec: filter.pro_doc_mec,
      limit: pageSize,
      page: currentPage
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const getSelectData = () => {
    setformLoading(true)
    return Promise.all([
      api.getsysProjectChilds({ pid: 0 }),
      // api.getsysProjectChilds({ pid: 0 }),
      api.getCommon({ type: 'staff_job_position' }),
    ]).then(list => {
      setformLoading(false)
      setSelectData({
        projectArr: list[0],
        // productArr: list[1],
        proPosition: list[1],
        isRequest: true
      })
      return list
    }).catch(() => setformLoading(false))
  }

  // 筛选项目
  const onSelectProfile = (val, valtwo) => {
    setPid(val)
    setFieldsValue({
      product_id: undefined,
      group_id: undefined
    })
    api.getDiscountProjectLeader({ projectName: valtwo.props.children }).then(data => {
      if (data.length === 0) {
        message.error('该项目没有项目负责人,请联系管理员设置')
      }
      setProjectName(data.name)
      setProjectId(data.id)
    })
    api.getsysProjectChilds({ pid: val }).then(data => {
      setProSection(data)
      setProSectionFlag(false)
    })
    api.getDiscountIndustryGroups({ projectId: val }).then(data => {
      setIndustryGroups(data)
      setIndGroupFlag(false)
    })
  }

  // 提交添加
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if (editId) {
          vals.id = editId
        }
        if (!projectId) {
          message.error('该项目没有项目负责人,请联系管理员设置')
          return
        }
        setSaveLoading(true)
        api.setFortuneDiscountBusiness({
          id: vals.id,
          project_id: vals.project_id,
          product_id: vals.product_id,
          group_id: vals.group_id,
          direct_one_new: vals.direct_one_new,
          direct_one_renew: vals.direct_one_renew,
          direct_one_approvelid: vals.direct_one_approvelid,
          direct_two_new: vals.direct_two_new,
          direct_two_renew: vals.direct_two_renew,
          direct_two_approvelid: vals.direct_two_approvelid,
          ditch_one_new: vals.ditch_one_new,
          ditch_one_renew: vals.ditch_one_renew,
          ditch_one_approvelid: vals.ditch_one_approvelid,
          ditch_two_new: vals.ditch_two_new,
          ditch_two_renew: vals.ditch_two_renew,
          ditch_two_approvelid: vals.ditch_two_approvelid,
          project_three_new: vals.project_three_new,
          project_three_renew: vals.project_three_renew,
          project_leader: projectId,
        })
          .then(() => {
            setSaveLoading(false)
            seteditVisible(false)
            message.success('设置成功')
            getDiscountBusinessList()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  const onExportFile = () => {
    setShowExport(true)
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}&activeKey=${Key}`)
    getDiscountBusinessList()
  }

  const departInp = useRef(null)

  const onTopSearch = () => {
    filter.pro_doc_mec = departInp.current.value
    history.replace(match.path + '?activeKey=' + 1)
    currentPage = 1
    getDiscountBusinessList()
  }

  const onTopReset = () => {
    filter.pro_doc_mec = ''
    departInp.current.value = ''
    currentPage = 1
    history.replace(match.path + '?activeKey=' + 1)
    getDiscountBusinessList()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedCount(selectedRowKeys.length)
      selectedIds = selectedRowKeys
    }
  }

  // 确定取消审核
  const onpromptOk = () => {
    api.getDiscountUpdateTrue({ id: orderId }).then(() => {
      getDiscountBusinessList()
      message.success('操作成功')
      setPromptVisible(false)
    })
  }

  // 取消确定
  const onpromptCancel = () => {
    setPromptVisible(false)
    setOrderId([])
  }

  // 编辑
  const onEdit = (data) => {
    setBusinessFlag(true)
    api.getDiscountIsHasApproval({
      projectId: data.project_id,
      productId: data.product_id
    }).then(res => {
      if (res.status == 1) {
        setPromptVisible(true)
        setOrderId(res.id)
      } else {
        // setPromptVisible(false)
        resetFields()
        seteditVisible(true)
        setDrawerName('编辑折扣配置')
        editId = data.id
        getSelectData().then(() => {
          setFieldsValue({
            project_id: data.project_id,
            product_id: data.product_id,
            group_id: data.group_id == 0 ? undefined : data.group_id,
            direct_one_new: data.direct_one_new,
            direct_one_renew: data.direct_one_renew,
            direct_one_approvelid: Number(data.direct_one_approvelid),
            direct_two_new: data.direct_two_new,
            direct_two_renew: data.direct_two_renew,
            direct_two_approvelid: Number(data.direct_two_approvelid),
            ditch_one_new: data.ditch_one_new,
            ditch_one_renew: data.ditch_one_renew,
            ditch_one_approvelid: Number(data.ditch_one_approvelid),
            ditch_two_new: data.ditch_two_new,
            ditch_two_renew: data.ditch_two_renew,
            ditch_two_approvelid: Number(data.ditch_two_approvelid),
            project_three_new: data.project_three_new,
            project_three_renew: data.project_three_renew,
            // project_leader: data.project_leader,
          })
          setProjectName(data.project_leaderName)
          setProjectId(data.project_leader)
          api.getsysProjectChilds({ pid: data.project_id }).then(data => {
            setProSection(data)
            setProSectionFlag(true)
          })
          api.getDiscountIndustryGroups({ projectId: data.project_id }).then(data => {
            setIndustryGroups(data)
            setIndGroupFlag(false)
          })
        })
      }
    })
  }

  const onDelete = (data) => {
    api.getDiscountIsHasApproval({
      projectId: data.project_id,
      productId: data.product_id
    }).then(res => {
      if (res.status == 1) {
        setPromptVisible(true)
        setOrderId(res.id)
      } else {
        api.getDiscountBusniessDel({ id: data.id }, true).then(() => {
          message.success('删除成功')
          for(let i = 0; i < selectedIds.length; i++){
            if(selectedIds[i] === data.id){
              selectedIds.splice(i, 1)
              setSelectedCount(selectedIds.length)
              i--
            }
          }
          getDiscountBusinessList()
        })
      }
    })
  }

  const onAddBusiness = () => {
    editId = ''
    setPid('')
    seteditVisible(true)
    setDrawerName('新增折扣配置')
    resetFields()
    setBusinessFlag(false)
    setProSectionFlag(true)
    setIndGroupFlag(true)
    setProjectName(undefined)
    setProjectId(undefined)
    if (!selectData.isRequest) {
      getSelectData()
    }
  }

  // 项目筛选
  const projectSearch = (value) => {
    api.getsysProjectChilds({ keyword: value, pid: 0, limit: global.paramsLimit, })
      .then(data => {
        setSelectData({
          ...selectData,
          projectArr: data,
        })
      }).catch()
  }

  // 产品筛选
  const productSearch = (val) => {
    api.getsysProjectChilds({ pid: pId, keyword: val }).then(data => {
      setProSection(data)
    })
  }
  // 行业分组筛选
  const indGroupSearch = (val) => {
    api.getDiscountIndustryGroups({ projectId: val }).then(data => {
      setIndustryGroups(data)
    })
  }

  //导入
  const onImportFile = () => {
    setVisibleFile(true)
    ImportRef.current.onRemoved()
  }

  const onCloseImport = () => {
    setVisibleFile(false)
  }

  const onSearch = (fileList) => {
    if (fileList.length > 0) {
      if (fileList[0].status !== "removed") {
        api.setDiscountBusinessImport({
          url: fileList[0].url
        }).then(data => {
          message.success('导入成功，请到导入列表查看。')
          ImportRef.current.onLoading()
          setVisibleFile(false)
          window.location.reload()
        }).catch(data => {
          ImportRef.current.onLoading()
        })
      } else {
        message.error('请导入模板')
      }
    } else {
      message.error('请导入模板')
    }
  }

  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onTopSearch()
    }
  }

  return (
    <>
      <div className="businessStySea">
        <div className="filter-wrap" style={{paddingBottom: 31}}>
          <h4 className="title">搜索：</h4>
          <div className="filter-input">
            <label htmlFor=""></label>
            <input className="ant-input" placeholder="项目/产品/供应商" ref={departInp} type="text" onKeyUp={onKeyup} />
          </div>
          <BtnGroup cancelName="重置" confirmName="筛选" onConfirm={onTopSearch} onCancel={onTopReset} />
        </div>
        <div className="businessStyLine"></div>
      </div>
      <>
        <div className="businessSty">
          <Auth auths={authList} code="operate">
            <Button type="primary" icon="upload" onClick={onExportFile}>导出</Button>
            <Button type="primary" icon="download" onClick={onImportFile} >导入</Button>
            <Button type="primary" icon="plus" onClick={onAddBusiness}>新增折扣配置</Button>
          </Auth>
        </div>
        <Alert className="corp-count" message={
          <>
            <span>已选择 <span style={{ color: '#168FFF' }}>{selectedCount}</span> 项</span>
            <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
          </>
        } type="info" showIcon />
        {/* <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon /> */}
        <Table
          size="middle"
          dataSource={list}
          rowKey="id"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          scroll={{ x: 1800 }}
          onChange={onChangeTable}
          rowSelection={rowSelection}
        >
          <Column title="序号" dataIndex="id" fixed="left" width={60} />
          <Column title="项目" dataIndex="project_name" fixed="left" width={160} />
          <Column title="产品" dataIndex="product_name" fixed="left" width={120} />
          <Column title="行业分组" dataIndex="groupName" fixed="left" width={160} />
          <Column title="直销一级新开" dataIndex="direct_one_new" />
          <Column title="直销一级续费" dataIndex="direct_one_renew" />
          <Column title="直销一级审批" dataIndex="direct_one_approvelname" />
          <Column title="直销二级新开" dataIndex="direct_two_new" />
          <Column title="直销二级续费" dataIndex="direct_two_renew" />
          <Column title="直销二级审批" dataIndex="direct_two_approvelname" />
          <Column title="渠道一级新开" dataIndex="ditch_one_new" />
          <Column title="渠道一级续费" dataIndex="ditch_one_renew" />
          <Column title="渠道一级审批" dataIndex="ditch_one_approvelname" />
          <Column title="渠道二级新开" dataIndex="ditch_two_new" />
          <Column title="渠道二级续费" dataIndex="ditch_two_renew" />
          <Column title="渠道二级审批" dataIndex="ditch_two_approvelname" />
          <Column title="项目三级新开" dataIndex="project_three_new" />
          <Column title="项目三级续费" dataIndex="project_three_renew" />
          <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
            <>
              {
               <Auth auths={authList} code="operate">
                  <Tooltip title="编辑" placement="bottom">
                      <Icon className="operate-icon" style={{ color: '#168FFF' }} type="edit" onClick={() => onEdit(record)} />
                  </Tooltip>
                </Auth>
              }
              {' '}
              {
                <Auth auths={authList} code="operate">
                  <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record)}>
                    <Tooltip title="删除" placement="bottom">
                      <Icon className="operate-icon" type="delete" style={{ color: 'red' }} />
                    </Tooltip>
                  </Popconfirm>
                </Auth>
              }
            </>
          )} />
        </Table>
        <Drawer
          title={drawerName}
          width={820}
          onClose={() => seteditVisible(false)}
          visible={editVisible}
        >
          <Spin spinning={formLoading}>

            <Form onSubmit={formSubmit} className="businessForm">
              <div className="businessFormTop">
                <FormItem label="项&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;目">
                  {getFieldDecorator('project_id', {
                    rules: [{ required: true, message: '请选择' }]
                  })(
                    <Select placeholder="请选择"
                      disabled={businessFlag}
                      filterOption={false}
                      onSelect={onSelectProfile}
                      onSearch={projectSearch}
                      autoFocus
                      showSearch={true}>
                      {selectData.projectArr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
                <FormItem label="产&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;品">
                  {getFieldDecorator('product_id', {
                    rules: [{ required: true, message: '请选择' }]
                  })(
                    <Select placeholder="请选择"
                      disabled={proSectionFlag}
                      filterOption={false}
                      // onSelect={onSelectProfileDepartment}
                      onSearch={productSearch}
                      autoFocus
                      showSearch={true}>
                      {proSection.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
                <FormItem label="行业分组">
                  {getFieldDecorator('group_id', {
                    rules: [{ required: false, message: '请选择' }]
                  })(
                    <Select placeholder="请选择"
                      disabled={indGroupFlag}
                      filterOption={false}
                      onSearch={indGroupSearch}
                      autoFocus
                      showSearch={true}>
                      {industryGroups.map(item => <Option key={item.id} value={item.id}>{item.groupName}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </div>
              <div className="lineOfsty">
                <FormItem label="直销一级新开" className="lineOfstyFormItem">
                  {getFieldDecorator('direct_one_new', {
                    rules: [{ required: true, message: '请输入折扣' },
                    { pattern: /^(0\.\d{4}|1\.0{4})$/, message: '大于等于0小于等于1,精确到小数点后四位,不足四位用0补足' }]
                  })(
                    <Input placeholder="请输入折扣" style={{ width: 180 }} />
                  )}
                </FormItem>
                <FormItem label="直销一级续费" className="lineOfstyFormItem">
                  {getFieldDecorator('direct_one_renew', {
                    rules: [{ required: true, message: '请输入折扣' },
                    { pattern: /^(0\.\d{4}|1\.0{4})$/, message: '大于等于0小于等于1,精确到小数点后四位,不足四位用0补足' }]
                  })(
                    <Input placeholder="请输入折扣" style={{ width: 180 }} />
                  )}
                </FormItem>
              </div>
              <FormItem label="直销一级审批">
                {getFieldDecorator('direct_one_approvelid', {
                  rules: [{ required: true, message: '请选择直销一级审批' }]
                })(
                  <Select placeholder="请选择"
                    filterOption={false}
                    // onSelect={onSelectProfileJobPosition}
                    // onSearch={jobPositionNameSelect}
                    autoFocus
                    showSearch={true}>
                    {selectData.proPosition.map(item => {
                      return <Option key={item.id} value={item.id}>{item.name}</Option>
                    }
                    )}
                  </Select>
                )}
              </FormItem>

              <div className="lineOfsty">
                <FormItem label="直销二级新开" className="lineOfstyFormItem">
                  {getFieldDecorator('direct_two_new', {
                    rules: [{ required: true, message: '请输入折扣' },
                    { pattern: /^(0\.\d{4}|1\.0{4})$/, message: '大于等于0小于等于1,精确到小数点后四位,不足四位用0补足' }]
                  })(
                    <Input placeholder="请输入折扣" style={{ width: 180 }} />
                  )}
                </FormItem>
                <FormItem label="直销二级续费" className="lineOfstyFormItem">
                  {getFieldDecorator('direct_two_renew', {
                    rules: [{ required: true, message: '请输入折扣' },
                    { pattern: /^(0\.\d{4}|1\.0{4})$/, message: '大于等于0小于等于1,精确到小数点后四位,不足四位用0补足' }]
                  })(
                    <Input placeholder="请输入折扣" style={{ width: 180 }} />
                  )}
                </FormItem>
              </div>
              <FormItem label="直销二级审批">
                {getFieldDecorator('direct_two_approvelid', {
                  rules: [{ required: true, message: '请选择直销二级审批' }]
                })(
                  <Select placeholder="请选择"
                    filterOption={false}
                    // onSelect={onSelectProfileJobPosition}
                    // onSearch={jobPositionNameSelect}
                    autoFocus
                    showSearch={true}>
                    {selectData.proPosition.map(item => {
                      return <Option key={item.id} value={item.id}>{item.name}</Option>
                    }
                    )}
                  </Select>
                )}
              </FormItem>

              <div className="lineOfsty">
                <FormItem label="渠道一级新开" className="lineOfstyFormItem">
                  {getFieldDecorator('ditch_one_new', {
                    rules: [
                      { required: true, message: '请输入折扣' },
                      { pattern: /^(0\.\d{4}|1\.0{4})$/, message: '大于等于0小于等于1,精确到小数点后四位,不足四位用0补足' }
                    ]
                  })(
                    <Input placeholder="请输入折扣" style={{ width: 180 }} />
                  )}
                </FormItem>
                <FormItem label="渠道一级续费" className="lineOfstyFormItem">
                  {getFieldDecorator('ditch_one_renew', {
                    rules: [{ required: true, message: '请输入折扣' },
                    { pattern: /^(0\.\d{4}|1\.0{4})$/, message: '大于等于0小于等于1,精确到小数点后四位,不足四位用0补足' }]
                  })(
                    <Input placeholder="请输入折扣" style={{ width: 180 }} />
                  )}
                </FormItem>
              </div>
              <FormItem label="渠道一级审批">
                {getFieldDecorator('ditch_one_approvelid', {
                  rules: [{ required: true, message: '请选择渠道一级审批' }]
                })(
                  <Select placeholder="请选择"
                    filterOption={false}
                    // onSelect={onSelectProfileJobPosition}
                    // onSearch={jobPositionNameSelect}
                    autoFocus
                    showSearch={true}>
                    {selectData.proPosition.map(item => {
                      return <Option key={item.id} value={item.id}>{item.name}</Option>
                    }
                    )}
                  </Select>
                )}
              </FormItem>
              <div className="lineOfsty">
                <FormItem label="渠道二级新开" className="lineOfstyFormItem">
                  {getFieldDecorator('ditch_two_new', {
                    rules: [{ required: true, message: '请输入折扣' },
                    { pattern: /^(0\.\d{4}|1\.0{4})$/, message: '大于等于0小于等于1,精确到小数点后四位,不足四位用0补足' }]

                  })(
                    <Input placeholder="请输入折扣" style={{ width: 180 }} />
                  )}
                </FormItem>
                <FormItem label="渠道二级续费" className="lineOfstyFormItem">
                  {getFieldDecorator('ditch_two_renew', {
                    rules: [{ required: true, message: '请输入折扣' },
                    { pattern: /^(0\.\d{4}|1\.0{4})$/, message: '大于等于0小于等于1,精确到小数点后四位,不足四位用0补足' }]
                  })(
                    <Input placeholder="请输入折扣" style={{ width: 180 }} />
                  )}
                </FormItem>
              </div>
              <FormItem label="渠道二级审批">
                {getFieldDecorator('ditch_two_approvelid', {
                  rules: [{ required: true, message: '请选择渠道二级审批' }]
                })(
                  <Select placeholder="请选择"
                    filterOption={false}
                    // onSelect={onSelectProfileJobPosition}
                    // onSearch={jobPositionNameSelect}
                    autoFocus
                    showSearch={true}>
                    {selectData.proPosition.map(item => {
                      return <Option key={item.id} value={item.id}>{item.name}</Option>
                    }
                    )}
                  </Select>
                )}
              </FormItem>
              <div className="lineOfsty">
                <FormItem label="项目三级新开" className="lineOfstyFormItem">
                  {getFieldDecorator('project_three_new', {
                    rules: [{ required: true, message: '请输入折扣' },
                    { pattern: /^(0\.\d{4}|1\.0{4})$/, message: '大于等于0小于等于1,精确到小数点后四位,不足四位用0补足' }]
                  })(
                    <Input placeholder="请输入折扣" style={{ width: 180 }} />
                  )}
                </FormItem>
                <FormItem label="渠项目三级续费" className="lineOfstyFormItem">
                  {getFieldDecorator('project_three_renew', {
                    rules: [{ required: true, message: '请输入折扣' },
                    { pattern: /^(0\.\d{4}|1\.0{4})$/, message: '大于等于0小于等于1,精确到小数点后四位,不足四位用0补足' }]
                  })(
                    <Input placeholder="请输入折扣" style={{ width: 180 }} />
                  )}
                </FormItem>
              </div>
              <div>项目负责人：{projectName}</div>
              <div style={{ height: 60 }}></div>
              <div className="setBusinessCloseBtn">
                <BtnGroup onCancel={() => seteditVisible(false)} loading={saveLoading} />
              </div>
            </Form>
          </Spin>
          {/* 修改判断 */}
        </Drawer>
        <Modal
          title="提示"
          visible={promptVisible}
          onOk={onpromptOk}
          // onCancel={() => setPromptVisible(false)}
          onCancel={onpromptCancel}
        >
          <p>该产品有未审批完成的折扣，请确认是否修改该产品的折扣配置表，折扣配置表修改成功后，将驳回该产品未审批通过的折扣申请.</p>
        </Modal>
        <Export
          show={showExport}
          onCancel={() => {
            setShowExport(false)
            productsRef.current.changeVal()
          }}
          onConfirm={() => { }}
          tplUrl="getDiscountExportTpl"
          fieldsUrl="getDiscountTplItems"
          saveUrl="saveDiscountTpl"
          exportUrl="exportDiscountExport"
          method="businessList"
          parame={{ ExportType: 'discount.businessList', id: selectedIds, ...filter }}
          cRef={productsRef}
        />
        <Import
          onSearch={onSearch}
          visibleFile={visibleFile}
          onCloseImport={onCloseImport}
          ImportTemplate="/api/File/DownTemplate?url=/templates/business.xlsx&name=业务部门折扣配置导入模板"
          ImportRef={ImportRef}
        />
      </>
    </>
  )
}

export default Form.create()(Business)