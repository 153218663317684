/**
 * 模块名称: 折扣配置表
 * @author zhuyan@372163.com
 */

import '../assets/style.scss'
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import DiscountTable from './DiscountTable'
import CompanyDetail from './module/CompanyDetail'

const DiscountTableIndex = ({ match, history }) => {

  return (
    <div>
      <Switch>
        <Route path={match.path + '/discountTable'} component={DiscountTable} />
        <Route path={match.path + '/detail'} component={CompanyDetail} />
        <Route component={DiscountTable} />
      </Switch>
    </div>
  )
}

export default DiscountTableIndex;
