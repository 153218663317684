/**
 * 模块名称: 设置负责人
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Button,
  // Input,
  Icon,
  Popconfirm,
  message,
  Select,
} from 'antd'

const { Column } = Table
const Option = Select.Option
const EditableContext = React.createContext()

const SetHead = (props) => {
  const { setHeadVisible, getList, sysProjectId } = props
  const [peopleList, setPeopleList] = useState([])
  const [loading, setLoading] = useState(false)
  const [cateEditVal, setCateEditVal] = useState('')
  const [editingCateKey, setEditingCateKey] = useState('')
  const [leaderList, setLeaderList] = useState([])
  const [staffId, setStaffId] = useState('')
 
  useEffect(() => {
    getPeopleList()
    getLeaderList()
  }, [])

  const getPeopleList = () => {
    setLoading(true)
    api.getSysProjectPrincipalList({
      sysProjectId: sysProjectId,
    }).then(data => {
      // console.log("data", data)
      setPeopleList(data)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  // 负责人
  const getLeaderList = () => {
    setLoading(true)
    api.getLeaderList({ limit: global.paramsLimit, minJobPosition: 5 }).then(data => {
      setLeaderList(data.list)  
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const isCateEditing = record => {
    // console.log("editingCateKey",record)
    return record.companyId === editingCateKey
  }

  const getstaffId = (e) => {
    setStaffId(e)
  }

  // 编辑负责人
  const editCateSave = (val) => {
    if (staffId == '') {
      message.error('负责人不能为空')
      return
    }
    // if (cateEditVal.indexOf(" ") >= 0) {
    //   message.error('标签内不得输入空格')
    //   return
    // }
    api.saveSysProjectPrincipal({
      id: val.id,
      sysProjectId: sysProjectId,
      companyId: val.companyId,
      staffId: staffId
    }).then(res => {
      message.success('保存成功')
      setEditingCateKey('')
      // setStaffId('')
      getPeopleList()
    })
  }

  const editCateCancel = (id) => {
    setEditingCateKey('')
  }

  const editCate = (record) => {
    // setCateEditVal(record.name)
    setStaffId(record.staffId === 0 ? '': record.staffId)
    setEditingCateKey(record.companyId)
  }

  const onChangeCateVal = (e) => {
    e.persist()
    setCateEditVal(e.target.value)
  }

  const onIndusClose = () => {
    setHeadVisible(false)
    getList()
  }

  // 筛选姓名
  const nameSelect = (value) => {
    api.getLeaderList({ limit: global.paramsLimit, minJobPosition: 5, staffName: value})
    .then(data => {
      setLeaderList(data.list)
    }).catch()
  }
  
  return (
    <>
      <div className="setIndustry setHead">
        <EditableContext.Provider>
          <Table
            dataSource={peopleList}
            rowKey="companyId"
            loading={loading}
            pagination={false}
          >
            <Column title="序号" dataIndex="companyId" width={'15%'} />
            <Column title="公司" dataIndex="companyName" />
            <Column title="负责人"
              width={'35%'}
              render={(text, record) =>
                <>
                
                  {isCateEditing(record) ? 
                  <div>
                     <Select placeholder="请输入姓名"
                        filterOption={false}
                        onSelect={getstaffId}
                        onSearch={nameSelect}
                        defaultValue={text.staffName} 
                        autoFocus
                        dropdownMatchSelectWidth={false}
                        showSearch={true}>
                        {
                        leaderList.map(text => <Option key={text.id} value={text.id}>{text.staffName}</Option>)
                      }
                      </Select>
                  </div>
                  // <div>
                  //   <Input 
                  //     onPressEnter={() => editCateSave(record.id)} 
                  //     defaultValue={text.staffName} 
                  //     onChange={onChangeCateVal} 
                  //   />
                  //   </div> 
                    :
                     <div style={{ lineHeight: '32px' }}>{text.staffName}</div>}
                </>
              }
            />
            <Column title="操作"
              render={(text, record) => {
                const editable = isCateEditing(record)
                return editable ? (
                  <span>
                    <Popconfirm title="确定要取消编辑吗？" onConfirm={() => editCateCancel(record.id)}>
                      <Icon className="operate-icon" type="close" style={{ color: '#F35D58' }} />
                    </Popconfirm>
                    <EditableContext.Consumer>
                      {() => (
                        <a
                          onClick={() => editCateSave(record)}
                          style={{ marginRight: 8 }}
                        >
                          <Icon className="operate-icon" type="check" style={{ color: '#3A9F33' }} />
                        </a>
                      )}
                    </EditableContext.Consumer>
                  </span>
                ) : (
                    <>
                      <a disabled={editingCateKey !== ''} onClick={() => editCate(record)}>
                        <Icon className="operate-icon" type="edit" />
                      </a>
                    </>
                  )
              }}
            />
          </Table>
        </EditableContext.Provider>
        <div style={{height:60}}></div>
        <div className="setCloseBtn">
          {/* <BtnGroup cancelName="关闭" confirmName="确定" onConfirm={onIndusClose} onCancel={onIndusClose} /> */}
          <Button type="primary" onClick={onIndusClose}>关闭</Button>
        </div>
      </div>
    </>
  )
}

export default Form.create()(SetHead)