/**
 * 模块名称: 系统项目管理 
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ProjectMgt from './ProjectMgt'
import SetTheField from './module/SetTheField'
import SetFieldMag from './module/SetFieldMag'

const ProjectMgtIndex = ({ match, history }) => {

  return (
    <div>
      <Switch>
        <Route path={match.path + '/projectMgt'} component={ProjectMgt} />
        <Route path={match.path + '/setTheField'} component={SetTheField} />
        <Route path={match.path + '/setFieldMag'} component={SetFieldMag} />
        <Route component={ProjectMgt} />
      </Switch>
    </div>
  )
}

export default ProjectMgtIndex