/**
 * 模块名称: 设置二级行业
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Button,
  Input,
  Icon,
  Popconfirm,
  message,
  Modal,
} from 'antd'
// import BtnGroup from '@/components/BtnGroup'

const { Column } = Table
const EditableContext = React.createContext()

const Subsectors = (props) => {
  const { setSubVisible, getList, projectIdTwo, pId } = props
  const [subList, setSubList] = useState([])
  const [loading, setLoading] = useState(false)
  const [cateEditVal, setCateEditVal] = useState('')
  const [editingCateKey, setEditingCateKey] = useState('')
  const [addCateVisible, setAddCateVisible] = useState(false)
  const [newCateName, setNewCateName] = useState('')

  useEffect(() => {
    getSubsectors()
  }, [])

  const getSubsectors = () => {
    setLoading(true)
    api.getSysProjectIndustryList({
      sysProjectId: projectIdTwo,
      projectIndustryPid: pId
    }).then(data => {
      setSubList(data)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const isCateEditing = record => {
    return record.id === editingCateKey
  }

  // 编辑行业
  const editCateSave = (id) => {
    if (cateEditVal == '') {
      message.warning('行业不能为空')
      return
    }
    if (cateEditVal.indexOf(" ") >= 0) {
      message.warning('行业内不得输入空格')
      return
    }
    api.saveSysProjectIndustry({
      id: id,
      name: cateEditVal,
      sysProjectId: projectIdTwo
    }).then(res => {
      message.success('保存成功')
      setEditingCateKey('')
      getSubsectors()
    })
  }

  const editCateCancel = (id) => {
    setEditingCateKey('')
  }

  const editCate = (record) => {
    setCateEditVal(record.name)
    setEditingCateKey(record.id)
  }

  const onChangeCateVal = (e) => {
    e.persist()
    setCateEditVal(e.target.value)
  }

  // 删除标签
  const onDeleteCate = (id) => {
    api.updateSysProjectIndustryIsDel({ id, isDel: 1 }, true).then(() => {
      message.success('删除成功')
      getSubsectors()
    })
  }

  const getAddCateName = e => {
    setNewCateName(e.target.value)
  }

  // 添加以及行业
  const addCateType = () => {
    setNewCateName('')
    setAddCateVisible(true)
  }

  // 添加行业
  const onAddCateOperation = () => {
    if (!newCateName.length) {
      message.warning('行业不能为空')
      return
    }
    if (newCateName.indexOf(" ") >= 0) {
      message.warning('行业内不得输入空格')
      return
    }
    api.saveSysProjectIndustry({
      id: '',
      pid: pId,
      name: newCateName,
      sysProjectId: projectIdTwo
    }).then(res => {
      message.success('添加行业成功')
      setEditingCateKey('')
      getSubsectors()
      setAddCateVisible(false)
    })
  }

  const onIndusClose = () => {
    setSubVisible(false)
    getList()
  }

  return (
    <>
      <div className="setIndustry">
        <EditableContext.Provider>
          <Table
            dataSource={subList}
            rowKey="id"
            loading={loading}
            pagination={false}
          >
            <Column title="序号" dataIndex="id" width={'15%'} />
            <Column title="二级行业"
              render={(text, record) =>
                <>
                  {isCateEditing(record) ? <div><Input onPressEnter={() => editCateSave(record.id)} defaultValue={text.name} onChange={onChangeCateVal} /></div> : <div style={{ lineHeight: '32px' }}>{text.name}</div>}
                </>
              }
            />
            <Column title="所属一级行业" dataIndex="parentName" />
            <Column title="操作"
              render={(text, record) => {
                const editable = isCateEditing(record)
                return editable ? (
                  <span>
                    <Popconfirm title="确定要取消编辑吗？" onConfirm={() => editCateCancel(record.id)}>
                      <Icon className="operate-icon" type="close" style={{ color: '#F35D58' }} />
                    </Popconfirm>
                    <EditableContext.Consumer>
                      {() => (
                        <a
                          onClick={() => editCateSave(record.id)}
                          style={{ marginRight: 8 }}
                        >
                          <Icon className="operate-icon" type="check" style={{ color: '#3A9F33' }} />
                        </a>
                      )}
                    </EditableContext.Consumer>
                  </span>
                ) : (
                    <>
                      <a disabled={editingCateKey !== ''} onClick={() => editCate(record)}>
                        <Icon className="operate-icon" type="edit" />
                      </a>
                      <a disabled={editingCateKey !== ''}>
                        <Popconfirm title="确定要删除该类别吗？" onConfirm={() => onDeleteCate(record.id)}>
                          {
                            editingCateKey !== '' ?
                              <Icon className="operate-icon" type="delete" style={{ color: '#c8c8c8' }} />
                              :
                              <Icon className="operate-icon" type="delete" style={{ color: '#F35D58' }} />
                          }
                        </Popconfirm>
                      </a>
                    </>
                  )
              }}
            />
          </Table>
          <div className="type-add" onClick={addCateType}><span>+添加</span></div>
          <Modal
            title="添加二级行业"
            visible={addCateVisible}
            onOk={onAddCateOperation}
            onCancel={() => { setAddCateVisible(false) }}
            destroyOnClose={true}
          >
            <Input placeholder="请输入要添加的二级行业名称" onChange={getAddCateName} />
          </Modal>
        </EditableContext.Provider>
        <div style={{height:60}}></div>
        <div className="setShortCloseBtn">
          {/* <BtnGroup cancelName="关闭" confirmName="确定" onConfirm={onIndusClose} onCancel={onIndusClose} /> */}
          <Button type="primary" onClick={onIndusClose}>关闭</Button>
        </div>
      </div>
    </>
  )
}

export default Form.create()(Subsectors)