/**
 * 模块名称: 新增/编辑行业分组
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  Form,
  Input,
  Select,
  message,
  Tree,
  Button,
  Icon
} from 'antd'
import BtnGroup from 'components/BtnGroup'

const FormItem = Form.Item
const Option = Select.Option
const { TreeNode } = Tree

const IndDrawer = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const { editId, editProId, setEditVisible, getProIndGroupsList, watchFlag } = props
  const [saveLoading, setSaveLoading] = useState(false)
  const [selectData, setSelectData] = useState([])
  const [indList, setIndList] = useState([])
  const [selList, setSelList] = useState([])
  const [allKeysVals, setAllKyesVals] = useState([])
  const [indListShow, setIndListShow] = useState(false)

  useEffect(() => {
    if(editProId){
      getGetEditRow()
      onProIndGroupsGetAllIndustry(editProId)
    }
    getProjectOrProduct()
  }, [])

  //根据是否传值过来id判断是编辑还是新增
  const getGetEditRow = () => {
    // console.log('editId=>', editId)
    api.getProIndGroupsDetail({id: editId}).then(data => {
      setFieldsValue({
        'project': {
          key: data.projectId,
          label: data.project
        },
        'groupName': data.groupName
      })
    })
  }

  //获取项目列表
  const getProjectOrProduct = () => {
    api.getProjectOrProduct({ open: 1 }).then(list => {
      setSelectData(list)
    })
  }
  //获取行业分组
  const onProIndGroupsGetAllIndustry = projectId => {
    // console.log('projectId=>', projectId)
    setIndList([])
    setAllKyesVals([])
    setIndListShow(false)
    api.onProIndGroupsGetAllIndustry({ projectId }, true).then(res => {
      if(res.all && res.all.length){
        const list = res.all.map(item => ({...{...item, child: item.child.map(itemm => ({...itemm, disabled: false}))}, disabled: false}))
        if(res.check && !Array.isArray(res.check)){
          const arr = Object.entries(res.check)
          let nArr = []//选中的id
            , dArr = []//禁用的id
          arr.forEach(item => {
            if(item[1] == editId){
              nArr.push(+item[0])
            }else{
              dArr.push(+item[0])
            }
          })
          setSelList(nArr)
          // console.log('nArr, dArr => ', nArr, dArr)
          list.forEach((item, index, self) => {
            if(dArr.includes(item.id))self[index].disabled = true
            if(item.child && item.child.length){
              let ic = item.child
              ic.forEach((itemm, indexx, selff) => {
                if(dArr.includes(itemm.id))selff[indexx].disabled = true
              })
            }
          })
        }
        // console.log('转化后的list =>', list)
        setIndList(list)
      }
      const getAllList = data => data.reduce((prev, curr) => {
        return (curr.child && curr.child.length) ? prev.concat({id: curr.id, name: curr.name}, getAllList(curr.child)) : [...prev, {id: curr.id, name: curr.name}]
      }, [])
      res.all.length && setAllKyesVals(getAllList(res.all))
      setIndListShow(true)
    })
  }

  // 提交添加
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      if (!err) {
        setSaveLoading(true)
        let params = {
          projectId: vals.project.key,
          project: vals.project.label,
          groupName: vals.groupName,
          industryData: []
        }
        if(editId) params.id = editId
        allKeysVals.forEach(item => {
          if(selList.includes(item.id + '') || selList.includes(item.id)){
            params.industryData.push({
              id: item.id,
              name: item.name
            })
          }
        })
        if(!params.industryData.length){
          setSaveLoading(false)
          return message.error('行业分组信息不能为空')
        }
        api.onProIndGroupsHandle(params).then(() => {
          setSaveLoading(false)
          setEditVisible(false)
          message.success('操作成功')
          getProIndGroupsList()
        }).catch(() => setSaveLoading(false))
      }
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    }
  }
  //行业分组树
  const renderTreeNodes = data =>
    data.map(item => {
    if (item.child) {
      return (
        <TreeNode title={item.name} key={item.id} className="treeNodeBox" disabled={!watchFlag ? item.disabled : true}>
          <TreeNode 
            title={!watchFlag ? <div onClick={() => onChooseAll(item)}>全选</div> : <div style={{color: '#999', cursor: 'default'}}>全选</div>} 
            checkable={false} key={item.name} 
            className="treeNodeBoxSel"
          ></TreeNode>
          {renderTreeNodes(item.child)}
        </TreeNode>
      )
    }
    return <TreeNode key={item.id} title={item.name} {...item} disabled={!watchFlag ? item.disabled : true} />
  })
  //全选行业分组
  const onChooseAll = v => {
    // console.log(v)
    let arr = []
    if(!v.disabled)arr = [v.id + '']
    if(v.child && v.child.length){
      let childArr = v.child.reduce((prev, curr) => [...prev, !curr.disabled ? (curr.id + '') : null], [])
      arr = [...arr, ...childArr]
    }
    setSelList([...new Set([...selList, ...arr])].filter(item => item))
  }
  //点选行业分组
  const onCheck = (checkedKeys, e) => {
    // console.log('onCheck', checkedKeys, e)
    let arr = [...new Set([...checkedKeys.checked])]
    setSelList(arr)
  }
  //选择项目
  const onSelProject = e => {
    setSelList([])
    onProIndGroupsGetAllIndustry(e.key)
  }

  return (
    <Form className='configMsgIndustryForm' onSubmit={formSubmit} {...formItemLayout}>
      <FormItem label="项目名称">
        {getFieldDecorator('project', {
          rules: [{ required: true, message: '请选择项目' }]
        })(
          <Select 
            placeholder="请选择"
            optionFilterProp="children"
            showSearch={true}
            labelInValue
            onSelect={onSelProject}
            disabled={watchFlag ? true : editId ? true : false}
          >
            {selectData.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
          </Select>
        )}
      </FormItem>
      <FormItem label="行业分组名称">
        {getFieldDecorator('groupName', {
          rules: [{ required: true, message: '请输入行业分组名称' }]
        })(
          <Input placeholder="请输入行业分组名称" style={{ width: '100%' }} disabled={watchFlag} />
        )}
      </FormItem>
      <FormItem label={<><span style={{color: '#f5222d', fontSize: '14px', fontFamily: 'SimSun, sans-serif'}}>*</span> 行业选择</>} labelCol={{xs: { span: 4 }, sm: { span: 4 }}} wrapperCol={{xs: { span: 20 }, sm: { span: 20 }}}>
        {
          indList.length ? <Tree
            checkable
            selectable={false}
            checkedKeys={selList}
            checkStrictly={true}
            onCheck={(checkedKeys, e) => { onCheck(checkedKeys, e) }}
            defaultExpandAll={true}
          >
            {renderTreeNodes(indList)}
          </Tree> : indListShow ? <>暂无行业信息</> : <span><Icon type="info-circle" style={{color: '#f5222d'}} /> 请先选择项目</span>
        }
      </FormItem>
      <div className="setBusinessCloseBtn setIndustryCloseBtn">
        {watchFlag == false && <BtnGroup onCancel={() => setEditVisible(false)} loading={saveLoading} />}
        {watchFlag == true && <Button type="primary" onClick={() => setEditVisible(false)}>关闭</Button>}
      </div>
    </Form>
  )
}
export default Form.create()(IndDrawer)