/**
 * 模块名称: 折扣分组配置表
 * @author zhuyan@372163.com     
 */
import React, { useEffect } from 'react'
import { Tabs } from 'antd'
import Business from './Business'
import Company from './Company'
import Industry from './Industry'
import { parseSearch } from '@/utils'

let pageSize = 10
let tabActiveKey = "1"


const DiscountTable = (props) => {

  const { history, match } = props
  const { TabPane } = Tabs
  const search = parseSearch(props.location.search)

  useEffect(() => {
    pageSize = search.limit ? +search.limit : 10
  }, [])

  const onTabClick = (e) => {
    tabActiveKey = e
    history.replace(match.path + '?page=' + 1 + '&limit=' + pageSize + '&activeKey=' + tabActiveKey)
  }


  return (
    <div>
      <div className="one-main discountBSty">
        <Tabs type="card" activeKey={tabActiveKey} onTabClick={onTabClick}>
          <TabPane tab="业务部门折扣配置表" key="1">
            <Business props={props} />
          </TabPane>
          <TabPane tab="公司折扣配置表" key="2">
            <Company props={props} />
          </TabPane>
          <TabPane tab="行业分组管理" key="3">
            <Industry props={props} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default DiscountTable;
