/**
 * 模块名称: 系统项目管理 
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Form,
  Button,
  Tree,
  Input,
  Modal,
  Switch,
  Drawer,
  message,
  Spin,
  TreeSelect
} from 'antd'
import Import from 'components/Import'
import SetIndustry from './module/SetIndustry'
import SetHead from './module/SetHead'
import { Link } from 'react-router-dom'
import Auth from 'components/AuthMiddleware'
import BtnGroup from 'components/BtnGroup'

const { Search } = Input
const FormItem = Form.Item
const { SHOW_PARENT } = TreeSelect

let projectEditId = '' //项目编辑
let productEditId = '' //产品编辑
let businessEditId = '' //业务编辑

let datas = []

const ProjectMgt = (props) => {
  const { getFieldDecorator, setFieldsValue, validateFields } = props.form
  const ImportRef = useRef()
  const ImportRefVendor = useRef()
  const { match, location } = props
  const dataVal = location.state ? location.state : null
  const [loading, setLoading] = useState(false)
  const [gData, setList] = useState([])
  const [authList, setAuthList] = useState([])
  const [addProject, setAddProject] = useState(false) // 新增项目
  const [addProjectLoading, setAddProjectLoading] = useState(false)
  const [addProduct, setAddproduct] = useState(false) // 新增产品
  const [addProductLoading, setAddProductLoading] = useState(false)
  const [business, setBusiness] = useState(false) // 新增业务
  const [businessLoading, setBusinessLoading] = useState(false)

  const [projectName, setProjectName] = useState('') // 项目名
  const [projectAlias, setProjectAlias] = useState('') // 项目别名
  const [productName, setProductName] = useState('') // 产品名
  const [productAlias, setProductAlias] = useState('') // 产品别名
  const [productId, setProductId] = useState('')
  const [businessName, setBusinessName] = useState('') // 业务名
  const [businessId, setBusinessId] = useState('')

  const [expandedKeys, setExpandedKeys] = useState([])
  const [searchValue, setSearchValue] = useState(undefined)
  const [autoExpandParent, setAutoExpandParent] = useState(false)

  const [industryVisible, setIndustryVisible] = useState(false) // 设置行业
  const [headVisible, setHeadVisible] = useState(false) // 负责人
  const [sysProjectId, setSysProjectId] = useState('') // 行业id
  const [showImport, setShowImport] = useState(false)
  const [showVendorImport, setShowVendorImport] = useState(false) // 供应商导入
  const [showProjectDrawer, setShowProjectDrawer] = useState(false)
  const [projectList, setProjectList] = useState([])
  // const [areaProjects, setAreaProjects] = useState([])
  const [saveDepId, setSaveDepId] = useState(null)
  const [areaProjectLoading, setAreaProjectLoading] = useState(true)
  const [value, setValue] = useState('')

  useEffect(() => {
    if (dataVal !== null) {
      getSysProjectList().then(() => {
        let pid = []
        pid.push(dataVal.ppid, dataVal.pid)
        setExpandedKeys(pid)
      })
    } else {
      getSysProjectList()
    }
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getSysProjectList = (val) => {
    setLoading(true)
    return api.getSysProjectList({name: val}, true).then(data => {
      setList([])
      setList(data.list)
      setLoading(false)
      return data.list
    }).catch(() => setLoading(false))
  }

  const dataList = []
  const generateList = data => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i]
      const { id, name } = node
      dataList.push({ id, name })
      if (node.childs) {
        generateList(node.childs)
      }
    }
  }
  generateList(gData)

  const getParentKey = (key, tree) => {
    // console.log(tree)
    let parentKey
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i]
      if (node.childs) {
        if (node.childs.some(item => item.id === key)) {
          parentKey = node.id
        } else if (getParentKey(key, node.childs)) {
          parentKey = getParentKey(key, node.childs)
        } else if (!(node.childs.some(item => item.id === key)) && !(getParentKey(key, node.childs))) {
          parentKey = key
        }
      }
    }
    return parentKey
  }

  // 新增
  const addVal = (id, pid, name, alias, level) => {
    let data = { id, pid, name, alias, level }
    api.saveSysProject(data).then(data => {
      getSysProjectList()
      setAddProject(false)
      setAddproduct(false)
      setBusiness(false)
    }).catch()
  }

  // 新增项目
  const onAddProject = () => {
    setProjectName('')
    setProjectAlias('')
    setAddProject(true)
  }

  // 修改项目
  const onEditproject = (id) => {
    projectEditId = id
    setProjectName('')
    setProjectAlias('')
    setAddProject(true)
    setAddProjectLoading(true)
    api.getSysProjectinfo({ id }).then(data => {
      setAddProjectLoading(false)
      setProjectName(data.name)
      setProjectAlias(data.alias)
    }).catch(() => {
      setAddProjectLoading(false)
    })
  }

  // 项目名称
  const getProjectName = (e) => {
    setProjectName(e.target.value)
  }

  // 项目别名
  const getProjectAlias = (e) => {
    setProjectAlias(e.target.value)
  }

  // 项目确定
  const onAddProjectOk = () => {
    if (projectName.trim() === '') {
      message.error('项目名称不能为空')
      return
    }
    addVal(projectEditId ? projectEditId : '', 0, projectName, projectAlias, 0)
  }

  // 点击新增产品
  const onAddproduct = (productId) => {
    productEditId = ''
    setProductName('')
    setProductAlias('')
    setProductId(productId)
    setAddproduct(true)
  }

  // 修改产品
  const onEditproduct = (id) => {
    productEditId = id
    setProductName('')
    setProductAlias('')
    setAddproduct(true)
    setAddProductLoading(true)
    api.getSysProjectinfo({ id }).then(data => {
      setAddProductLoading(false)
      setProductName(data.name)
      setProductAlias(data.alias)
    }).catch(() => {
      setAddProductLoading(false)
    })
  }

  // 产品名
  const getProductName = (e) => {
    setProductName(e.target.value)
  }

  // 产品别名
  const getProductAlias = (e) => {
    setProductAlias(e.target.value)
  }

  // 产品新增确定
  const onAddProductOk = () => {
    if (productName.trim() === '') {
      message.error('产品名称不能为空')
      return
    }
    addVal(productEditId ? productEditId : '', productId, productName, productAlias)
  }

  // 点击新增业务
  const onBusiness = (businessId) => {
    businessEditId = ''
    setBusinessName('')
    setBusinessId(businessId)
    setBusiness(true)
  }

  // 业务修改
  const onEditBusiness = (id) => {
    businessEditId = id
    setBusinessName('')
    setBusiness(true)
    setBusinessLoading(true)
    api.getSysProjectinfo({ id }).then(data => {
      setBusinessLoading(false)
      setBusinessName(data.name)
    }).catch(() => {
      setBusinessLoading(false)
    })
  }

  //业务名称
  const getBusiness = (e) => {
    setBusinessName(e.target.value)
  }

  // 新增业务确认
  const onAddBusiness = () => {
    if (businessName.trim() === '') {
      message.error('业务名称不能为空')
      return
    }
    addVal(businessEditId ? businessEditId : '', businessId, businessName, '')
  }

  // 开关
  const onOneClick = (item) => {
    api.updateSysProjectIsOpen({ id: item.id, isOpen: item.isOpen == 0 ? 1 : 0 }).then(data => {
      getSysProjectList(value)
    }).catch()
  }

  const onTwoClick = (item) => {
    api.updateSysProjectIsOpen({ id: item.id, isOpen: item.isOpen == 0 ? 1 : 0 }).then(data => {
      getSysProjectList(value)
    }).catch()
  }

  const onThreeClick = (item) => {
    api.updateSysProjectIsOpen({ id: item.id, isOpen: item.isOpen == 0 ? 1 : 0 }).then(data => {
      getSysProjectList(value)
    }).catch()
  }

  // 项目行业导入
  const onImportIndustry = () => {
    ImportRef.current.onRemoved()
    setShowImport(true)
  }

  // 项目行业导入确定
  const onConfirmImport = (fileList) => {
    if (fileList[0].status !== 'removed') {
      api.setsysProjectImportExcel({
        path: fileList[0].url
      }).then(res => {
        getSysProjectList()
        message.success('导入成功')
        ImportRef.current.onLoading()
        setShowImport(false)
      }).catch(res => {
        // console.log('导入', res)
        ImportRef.current.onLoading()
      })
    } else {
      message.error('请导入模板')
    }
  }

  // 供应商行业导入
  const onImportVendor = () => {
    ImportRefVendor.current.onRemoved()
    setShowVendorImport(true)
  }

  // 供应商行业导入确定
  const onVendorImport = (fileList) => {
    if (fileList[0].status !== 'removed') {
      api.setVendorImportExcel({
        uploadUrl: fileList[0].url
      }).then(res => {
        getSysProjectList()
        message.success('导入成功')
        ImportRefVendor.current.onLoading()
        setShowVendorImport(false)
      }).catch(res => {
        // console.log('导入', res)
        ImportRefVendor.current.onLoading()
      })
    } else {
      message.error('请导入模板')
    }
  }

  // 点击设置行业
  const onIndustry = (id) => {
    setSysProjectId(id)
    setIndustryVisible(true)
  }

  // 点击负责人
  const onHead = (id) => {
    setSysProjectId(id)
    setHeadVisible(true)
  }

  // tree搜索
  const onExpand = (expandedKeys) => {
    // console.log('expandedKeys', expandedKeys)
    setExpandedKeys(expandedKeys)
    setAutoExpandParent(false)
  }

  const departInp = useRef(null)

  const onSeaReset = () => {
    setValue('')
    setSearchValue('')
    departInp.current.input.state.value = ''
    setAutoExpandParent(false)
    setExpandedKeys([])
    getSysProjectList()
  }

  const onSeaChange = async (value) => {
    setValue(value)
    // const { value } = e.target
    // await api.getSysProjectList({}, true).then(data => {
    //   setExpandedKeys([])
    //   setList(data.list)
    //   generateList(data.list)
    // }).catch(err => console.log(err))
    if(value !== '') {
      getSysProjectList(value)
      const expandedKey = dataList.map(item => {
        if (item.name.indexOf(value) > -1) {
          return getParentKey(item.id, gData)
        }
        return
      })
        .filter((item, i, self) => item && self.indexOf(item) === i).map(itemm => itemm + '')
      if (expandedKey.length === 0) {
        message.error("搜索内容不存在")
      }
      setExpandedKeys(expandedKey)
      setSearchValue(value)
      setAutoExpandParent(true)
    }
  }

  const getTitle = item => {
    // let index = -1
    // let beforeStr = ''
    // let afterStr = ''
    // if (searchValue) {
    //   index = item.name.indexOf(searchValue)
    //   beforeStr = item.name.substr(0, index)
    //   afterStr = item.name.substr(index + searchValue.length)
    // }   
    // console.log("searchValue",searchValue)
    // if (index > -1) {
    //   return <span>
    //         {beforeStr}
    //         <>
    //         <span className="site-tree-search-value">{searchValue}</span>
    //         </>
    //         {afterStr}
    //       </span>
    // } else {
    if (item.level == 0) {
      return <div className="levelSty">

        <span title={item.name} className="levelSty-span">{item.name}</span>
        <div className="levelSty-left">
          <span className="levelSty-left-id">id: {item.id}</span>
          <span className="levelSty-left-span"><span>别名:&nbsp;&nbsp;</span><span title={item.alias ? item.alias : '--'}>{item.alias ? item.alias : '--'}</span></span>
        </div>

        <div className="levelSty-right">
          <div className="levelSty-right-btn">
            <Auth auths={authList} code="operate">
              <a onClick={() => onHead(item.id)}>负责人</a>
              <a onClick={() => onIndustry(item.id)}>设置行业</a>
              <a onClick={() => onSetProject(item)}>设置主项</a>
            </Auth>
          </div>
          <div className="levelSty-right-Switch">
            <Auth auths={authList} code="operate">
              <Switch defaultChecked={item.isOpen === 0 ? false : true} onClick={() => onOneClick(item)} />
            </Auth>
          </div>
          <div className="levelSty-right-Flex">
            <Auth auths={authList} code="operate">
              {
                item.isOpen === 0 ? <a></a> :
                  <a onClick={() => onAddproduct(item.id)}>新增产品</a>
              }
              <a onClick={() => onEditproject(item.id)}>修改</a>
            </Auth>
          </div>
        </div>
      </div>
    } else if (item.level == 1) {
      return <div className="levelSty">
        <span title={item.name} className="levelSty-span">{item.name}</span>
        <div className="levelSty-left">
          <span className="levelSty-left-id">id: {item.id}</span>
          <span className="levelSty-left-span"><span>别名:&nbsp;&nbsp;</span><span title={item.alias ? item.alias : '--'}>{item.alias ? item.alias : '--'}</span></span>
        </div>
        <div className="levelSty-right">
          <div className="levelSty-right-btn">
            <a></a>
            <a></a>
          </div>
          <div className="levelSty-right-Switch">
            <Auth auths={authList} code="operate">
              <Switch defaultChecked={item.isOpen === 0 ? false : true} onClick={() => onTwoClick(item)} />
            </Auth>
          </div>
          <div className="levelSty-right-Flex">
            <Auth auths={authList} code="operate">
              {
                item.isOpen === 0 ? <a></a> :
                  <a onClick={() => onBusiness(item.id)}>新增业务</a>
              }
              <a onClick={() => onEditproduct(item.id)}>修改</a>
            </Auth>
          </div>
        </div>
      </div>
    } else {
      return <div className="levelSty">
        <span title={item.name} className="levelSty-span">{item.name}</span>
        <div className="levelSty-left">
          <span className="levelSty-left-id">id: {item.id}</span>
          <span className="levelSty-left-span"><span>别名:&nbsp;&nbsp;</span><span title={item.alias ? item.alias : '--'}>{item.alias ? item.alias : '--'}</span></span>
        </div>
        <div className="levelSty-right">
          <div className="levelSty-right-btn">
            <a></a>
            <a></a>
          </div>
          <div className="levelSty-right-Switch">
            <Auth auths={authList} code="operate">
              <Switch checked={item.isOpen === 0 ? false : true} onClick={() => onThreeClick(item)} />
            </Auth>
          </div>
          <div className="levelSty-right-Flex">
            <Auth auths={authList} code="operate">
              <Link to={`${match.path}/setTheField?id=${item.id}&ppid=${item.ppid}&pid=${item.pid}`}>订单字段</Link>
              <a onClick={() => onEditBusiness(item.id)}>修改</a>
            </Auth>
          </div>
        </div>
      </div>
    }
    // }
  }

  const loopData = data => {
    return data.map(item => {
      let title = getTitle(item)
      if (item.childs) {
        return { title, key: item.id, children: loopData(item.childs) }
      }
      return {
        title,
        key: item.id,
      }
    })
  }

  const onDragEnter = info => {
    // console.log('info', info)
  }

  const loop = (data, key, callback) => {
    data.forEach((item, index, arr) => {
      if (item.id === parseInt(key)) {
        return callback(item, index, arr)
      }
      if (item.childs) {
        return loop(item.childs, key, callback)
      }
    })
  }

  const loopIds = (data, ids) => {
    data.forEach((item, index, arr) => {
      ids.push(item.id)
    })
  }

  // 递归 获得自己的层级id数组
  const getItemAndParentsIdArr = (arr, key) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === parseInt(key)) {
        datas.push(arr[i])
        return true
      } else {
        if (
          arr[i].childs &&
          getItemAndParentsIdArr(arr[i].childs, key)
        ) {
          datas.push(arr[i])
          return true
        }
      }
    }
  }

  // 获得自己的父级id
  const getFirstParentId = (strings, key) => {
    let stringsArr = strings.split("/")
    let index = stringsArr.indexOf(key.toString())
    let parenId
    if (index !== 0) {
      parenId = stringsArr[index - 1]
    } else {
      parenId = stringsArr[index]
    }
    return parenId
  }

  const onDrop = info => {
    datas = []
    const dropKey = info.node.props.eventKey //拖拽落下的值
    const dragKey = info.dragNode.props.eventKey //被拖拽的值
    const dropPos = info.node.props.pos.split('-') //拖拽落下的位置 最外层到最里层
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])
    const data = [...gData]
    // Find dragObject
    let dragObj
    let arrays
    let drag = info.dragNode.props.pos
    let dragLength = info.dragNode.props.pos.split('-').length
    let drop = info.node.props.pos
    let dropLength = info.node.props.pos.split('-').length
    if (!info.dropToGap) {
      //平级与平级之间不能拖拽为子级 
      // if ((dropPosition == 0 && dropPos.length == 3) || 
      //     (dropPosition == 0 && dropPos.length == 2 && dragLength == 2)) {
      // } else {
      //   loop(data, dragKey, (item, index, arr) => {
      //     arr.splice(index, 1)
      //     dragObj = item
      //   })
      //   loop(data, dropKey, item => {
      //     item.childs = item.childs || []
      //     // where to insert 示例添加到尾部，可以是随意位置
      //     item.childs.push(dragObj)
      //   })
      // }
      // Drop on the content
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      // loop(data, dragKey, (item, index, arr) => {
      //   arr.splice(index, 1)
      //   dragObj = item
      // })
      // loop(data, dropKey, item => {
      //   item.childs = item.childs || []
      //   // where to insert 示例添加到头部，可以是随意位置
      //   item.childs.unshift(dragObj)
      // })
    }
    else {

      // 获得拖拽节点的层级id数组 this.datas
      getItemAndParentsIdArr(data, dragKey)
      // console.log("datadata111",datas)
      let dragKeyString = datas
        .reverse()
        .map((v, i) => {
          return v.id
        })
        .join("/")

      // console.log("dragKeyString111",dragKeyString, dragKey)
      // 拖拽节点父级id
      let dragKeyParentId = getFirstParentId(dragKeyString, dragKey)
      // 获得目标节点的层级id数组 this.datas
      getItemAndParentsIdArr(data, dropKey)
      // console.log("datadata222",datas)
      let dropKeyString = datas
        .reverse()
        .map((v, i) => {
          return v.id
        })
        .join("/")

      // console.log("dragKeyString222",dropKeyString, dropKey)
      // 目标节点父级id
      let dropKeyParentId = getFirstParentId(dropKeyString, dropKey)

      // console.log("dragKeyParentId",dragKeyParentId,dropKeyParentId)
      // console.log("dragLength",dragLength,dropLength)

      // if (dragKeyParentId === dropKeyParentId || (dragLength === 2 && dropLength === 2))
      // if (dragKeyParentId !== dropKeyParentId) {
      //   return
      // }


      if (dragKeyParentId === dropKeyParentId && (dragLength === dropLength) || (dragLength === 2 && dropLength === 2)) {
        loop(data, dragKey, (item, index, arr) => {
          arrays = arr
          arr.splice(index, 1)
          dragObj = item
        })
        let ar
        let i
        loop(data, dropKey, (item, index, arr) => {
          ar = arr
          i = index
        })
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj)
        } else {
          ar.splice(i + 1, 0, dragObj)
        }
        setList(data)
        if (arrays) {
          let ids = []
          loopIds(arrays, ids)
          let str = ids.join(",")
          // console.log(str)
          api.updateSysProjectSort({ ids: str }).then(data => { }).catch()  // 排序
        }
      }
    }
  }

  const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 6 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 18 },
		}
  }

  //点击设置主项
  const onSetProject = data => {
    // console.log(data)
    setAreaProjectLoading(true)
    setSaveDepId(data.id)
    api.getProjectDepartment({id: data.id}).then(res => {
      const list = res.map(item => {
        return {
          title: item.name,
          value: item.id + '~' + (item.childs && item.childs.length ? item.childs.map(i => i.id) : []),
          key: item.id,
          children: item.childs && item.childs.length ? item.childs.map(itemm => ({
            title: itemm.name,
            value: item.id + '-' + itemm.id,
            key: itemm.id
          })) : []
        }
      })
      // console.log(list)
      setProjectList(list)
      const arr = []
      for(let i = 0, len = res.length; i<len; i++){
        arr[i] = []
        if(res[i].childs){
          if(res[i].childs.length){
            for(let j = 0, lenn = res[i].childs.length; j < lenn; j++){
              let son = res[i].childs[j]
              if(son.isChoosed){
                arr[i][j] = res[i].id + '-' + son.id
              }
            }
          }else{
            if(res[i].isChoosed){
              arr[i] = ([res[i].id + '~'])
            }
          }
        }
      }
      // console.log((arr + '').split(','))
      let timer = setTimeout(() => {
        setFieldsValue({
          'areaProject': (arr + '').split(',').filter(item => item)
        })
        clearTimeout(timer)
        setAreaProjectLoading(false)
      }, 1500)
    })
    setShowProjectDrawer(true)
  }

  const tProps = {
		showSearch: true,
		treeNodeFilterProp: "title",
		treeData: projectList,
		// onChange: onChangeCheckPro,
		treeCheckable: true,
		showCheckedStrategy: SHOW_PARENT,
		placeholder: '请选择',
		getPopupContainer: triggerNode => {
			// console.log(triggerNode)
			return triggerNode.parentNode
		},
		dropdownStyle: {
			maxHeight: 400, 
			overflow: 'auto'
		},
		style: {
			width: '82%',
			marginRight: '15px',
		}
	}

  //提交设置主项
  const onFormSubmit = e => {
    e.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
        // console.log(err, vals)
        const json = {}
        vals.areaProject.forEach(item => {
          if(item.indexOf('~') > -1){
            let arr = item.split('~')
            json[arr[0]] = arr[1].split(',')
          }
          if(item.indexOf('-') > -1){
            let arr = item.split('-')
            json[arr[0]] = []
          }
        })
        vals.areaProject.forEach(item => {
          if(item.indexOf('-') > -1){
            let arr = item.split('-')
            json[arr[0]].push(arr[1])
          }
        })
        // console.log(json)
        api.onProjectSaveDepartment({id: saveDepId, department: json}).then(() => {
          message.success('设置成功')
          setShowProjectDrawer(false)
        }).catch(() => setShowProjectDrawer(false))
			} else {
				for (let i in err) {
					message.error(err[i].errors[0].message)
				}
			}

		})
  }

  const onChange = (e) => {
    if (e.target.value == '') {
      setExpandedKeys([])
      getSysProjectList()
    }
  }

  return (
    <>
      <div className="projectMgt-title">
        <h4 className="title">项目设置</h4>
        <div className="projectMgt-wrap">
          <h4 className="title">搜索：</h4>
          <div className="projectMgt-item">
            <Search
              ref={departInp}
              style={{ marginBottom: 8 }}
              placeholder="项目/产品/业务类型"
              onSearch={onSeaChange}
              onChange={onChange}
              enterButton="筛选"
            />
          </div>
          <Button onClick={onSeaReset} className="projectMgt-item-btn">重置</Button>
        </div>
      </div>
      <div className="line"></div>
      <div className="add-projectMgt">
        <Auth auths={authList} code="operate">
          <Button type="primary" icon="plus" className="add-projectMgt-btn" onClick={onAddProject}>新增项目</Button>
          <Link to={`${match.path}/setFieldMag`}>
            <Button type="primary" className="add-projectMgt-btn">订单字段管理</Button>
          </Link>
          <Button type="primary" className="add-projectMgt-btn" onClick={onImportVendor}>供应商行业导入</Button>
          <Button type="primary" className="add-projectMgt-btn" onClick={onImportIndustry}>项目行业导入</Button>
        </Auth>
      </div>
      <div className="tree-projectMgt">
        <Tree
          showLine={true}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          treeData={loopData(gData)}
          draggable
          blockNode
          onDragEnter={onDragEnter}
          onDrop={onDrop}
        />
      </div>
      <Modal
        title={projectEditId ? '修改项目' : '新增项目'}
        visible={addProject}
        onOk={onAddProjectOk}
        onCancel={() => { setAddProject(false) }}
        destroyOnClose={true}
      >
        <Spin spinning={addProjectLoading}>
          <div className="modalStyle">
            <div className="modalStyleName"><span className="asterisk">*</span>项目名称</div>
            <Input placeholder="项目名称" value={projectName} onChange={getProjectName} />
          </div>
          <div className="modalStyle">
            <div className="modalStyleName">别名</div>
            <Input placeholder="别名" value={projectAlias} onChange={getProjectAlias} />
          </div>
        </Spin>
      </Modal>
      <Modal
        title={productEditId ? '修改产品' : '新增产品'}
        visible={addProduct}
        onOk={onAddProductOk}
        onCancel={() => { setAddproduct(false) }}
        destroyOnClose={true}
      >
        <Spin spinning={addProductLoading}>
          <div className="modalStyle">
            <div className="modalStyleName"><span className="asterisk">*</span>产品名称</div>
            <Input placeholder="产品名称" value={productName} onChange={getProductName} />
          </div>
          <div className="modalStyle">
            <div className="modalStyleName">别名</div>
            <Input placeholder="别名" value={productAlias} onChange={getProductAlias} />
          </div>
        </Spin>
      </Modal>
      <Modal
        title={businessEditId ? '修改业务' : '新增业务'}
        visible={business}
        onOk={onAddBusiness}
        onCancel={() => { setBusiness(false) }}
        destroyOnClose={true}
      >
        <Spin spinning={businessLoading}>
          <div className="modalStyle">
            <div className="modalStyleName"><span className="asterisk">*</span>业务名称</div>
            <Input placeholder="业务名称" value={businessName} onChange={getBusiness} />
          </div>
        </Spin>
      </Modal>
      <Drawer
        title="设置行业"
        width={820}
        onClose={() => setIndustryVisible(false)}
        visible={industryVisible}
        destroyOnClose={true}
      >
        <SetIndustry
          setIndustryVisible={setIndustryVisible}
          getList={getSysProjectList}
          sysProjectId={sysProjectId}
        />
      </Drawer>
      <Drawer
        title="设置负责人"
        width={820}
        onClose={() => setHeadVisible(false)}
        visible={headVisible}
        destroyOnClose={true}
      >
        <SetHead
          setHeadVisible={setHeadVisible}
          getList={getSysProjectList}
          sysProjectId={sysProjectId}
        />
      </Drawer>
      {/* 项目行业导入 */}
      <Import
        onSearch={onConfirmImport}
        visibleFile={showImport}
        onCloseImport={() => setShowImport(false)}
        ImportTemplate="/api/File/DownTemplate?url=/templates/industry.xlsx&name=项目行业导入模板"
        ImportRef={ImportRef}
      />
      {/* 供应商行业导入 */}  
      <Import
        onSearch={onVendorImport}
        visibleFile={showVendorImport}
        onCloseImport={() => setShowVendorImport(false)}
        ImportTemplate="/api/File/DownTemplate?url=/templates/supplierIndustryImport.xlsx&name=供应商行业导入模板"
        ImportRef={ImportRefVendor}
      />
      <Drawer
        visible={showProjectDrawer}
        width={700}
        onClose={() => setShowProjectDrawer(false)}
        title="设置主项"
        destroyOnClose={true}
      >
        <Spin spinning={areaProjectLoading}>
          <Form className="projectmgt-setmain-form" onSubmit={onFormSubmit} {...formItemLayout}>
            <FormItem label="设置主项">
              {getFieldDecorator('areaProject', {
                // initialValue: areaProjects,
                rules: [{ required: true, message: '请选择' }]
              })(<TreeSelect {...tProps} />)}
              <Button type="primary" onClick={() => setFieldsValue({ areaProject: [] })}>清空</Button>
            </FormItem>
            <BtnGroup onCancel={() => setShowProjectDrawer(false)} />
          </Form>
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(ProjectMgt)