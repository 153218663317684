/**
 * 模块名称: 行业分组配置
 * @author lids@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  Table,
  Drawer,
  Form,
  Alert,
  Button,
  message,
  Icon,
  Popconfirm,
  Spin,
  Tooltip,
  Switch,
  Input,
  Select
} from 'antd'
import Auth from 'components/AuthMiddleware'
import { parseSearch } from 'utils'
import IndDrawer from './module/IndDrawer'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option

let pageSize = 10
let currentPage = 1
let Key = ''

let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage
}

const Industry = (props) => {
  const { match, location, history } = props.props
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [authList, setAuthList] = useState([])
  const [editVisible, setEditVisible] = useState(false)
  const [drawerName, setDrawerName] = useState('新增行业分组')
  const [editData, setEditData] = useState({})
  const [watchFlag, setWatchFlag] = useState(false)
  const [proList, setProList] = useState([])
  const [filterIndList, setFilterIndList] = useState([])
  const [filterIndList2, setFilterIndList2] = useState([])
  const [indProjectId, setIndProjectId] = useState('')
  const [indVal1, setIndVal1] = useState('')

  const search = parseSearch(location.search)
  Key = search.activeKey ? +search.activeKey : '3'

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    resetFields()
    api.getPageAuth().then(list => setAuthList(list))
    getProIndGroupsList()
  }, [Key])
  //获取列表
  const getProIndGroupsList = () => {
    setLoading(true)
    api.getProIndGroupsList(searchParamsTmp).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //点击分页
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}&activeKey=${Key}`)
    getProIndGroupsList()
  }

  // 编辑
  const onEdit = (data) => {
    setEditData(data)
    setEditVisible(true)
    setWatchFlag(false)
    setDrawerName('编辑行业分组')
  }
  //查看行业分组
  const onWatch = data => {
    setEditData(data)
    setWatchFlag(true)
    setEditVisible(true)
    setDrawerName('查看行业分组')
  }
  //是否启用行业分组
  const onToggle = (data) => {
    // console.log(data)
    api.onProIndGroupsChangeStatus({id: data.id, status: data.status}, true).then(() => {
      message.success('操作成功')
      getProIndGroupsList()
    })
  }
  //增加行业分组
  const onAdd = () => {
    setEditData({})
    setEditVisible(true)
    setWatchFlag(false)
    setDrawerName('新增行业分组')
  }

  //点击筛选
  const searchSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      transData(vals)
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}&activeKey=${Key}`)
        getProIndGroupsList()
      }
    })
  }
  //数据转换
  const transData = (vals) => {
    searchParamsTmp = {...searchParamsTmp, ...vals}
    for(let item in searchParamsTmp){
      if(searchParamsTmp[item] === undefined){
        delete searchParamsTmp[item]
      }
    }
  }
  //重置
  const resetForm = () => {
    currentPage = 1
    setIndProjectId('')
    history.replace(match.path + '?activeKey=' + 3)
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    resetFields()
    getProIndGroupsList()
  }
  //获取项目列表
  const getProjectOrProduct = () => {
    api.getProjectOrProduct({ open: 0 }).then(list => {
      setProList(list)
    })
  }
  //获取1级行业列表
  const getProIndGroupsFilterIndustry = () => {
    let params = {
      open: 1,
      industryId: 0
    }
    let proId = getFieldValue('projectId')
    if(proId)params.projectId = proId
    api.getProIndGroupsFilterIndustry(params).then(res => {
      setFilterIndList(res)
    })
  }
  //获取2级行业列表
  const getProIndGroupsFilterIndustry2 = () => {
    let params = {
      open: 1
    }
    let proId = getFieldValue('projectId')
    if(proId)params.projectId = proId
    let industryOneId = getFieldValue('industryOneId')
    if(industryOneId)params.industryId = industryOneId
    api.getProIndGroupsFilterIndustry(params).then(res => {
      setFilterIndList2(res)
    })
  }

  return (
    <>
      <div className="businessStySea">
        <div className="search-role-wrap search-industry-wrap">
        {/* <h4 className="title">筛选选项：</h4> */}
        <Form onSubmit={searchSubmit} layout="vertical" >
          <div className="form-box askforleave-form-auto">
            <FormItem label="行业分组名称:">
              {getFieldDecorator('name')(<Input 
                placeholder="请输入" 
                style={{width: 200}}
              />)}
            </FormItem>
            <FormItem label="项目:">
              {getFieldDecorator('projectId')(<Select
                placeholder="全部" 
                onDropdownVisibleChange={bool => bool && getProjectOrProduct()}
                optionFilterProp="children"
                showSearch
                dropdownMatchSelectWidth={false}
                style={{width: 200}}
                onChange={e => {
                  setIndProjectId(e)
                  setIndVal1('')
                  setFieldsValue({
                    'industryOneId': undefined,
                    'industryTwoId': undefined
                  })
                }}
              >
                {
                  proList.map(item => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="一级行业:">
              {getFieldDecorator('industryOneId')(<Select
                placeholder={indProjectId == '' ? '请先选择项目' : '请选择'}
                onDropdownVisibleChange={bool => bool && getProIndGroupsFilterIndustry()}
                onChange={e => {
                  setIndVal1(e)
                  setFieldsValue({
                    'industryTwoId': undefined
                  })
                }}
                style={{width: 200}}
                disabled={indProjectId === ''}
              >
                {
                  filterIndList.map(item => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="二级行业:">
              {getFieldDecorator('industryTwoId')(<Select
                placeholder={indVal1 == '' ? '请先选择一级行业' : '请选择'}
                onDropdownVisibleChange={bool => bool && getProIndGroupsFilterIndustry2()}
                optionFilterProp="children"
                showSearch
                dropdownMatchSelectWidth={false}
                style={{width: 200}}
                disabled={indVal1 === ''}
              >
                {
                  filterIndList2.map(item => {
                    return <Option key={item.id} title={item.name}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <div className="" style={{position: 'absolute', right: 20, top: 70}}>
              <Button onClick={resetForm} style={{marginRight: 20}}>重置</Button>
              <Button type="primary" htmlType="submit">筛选</Button>
            </div>
          </div>
        </Form>
        </div>
        <div className="businessStyLine businessStyLines"></div>
      </div>
      <>
        <div className="businessSty">
          <Auth auths={authList} code="operate">
            <Button type="primary" icon="plus" onClick={onAdd}>新增</Button>
          </Auth>
        </div>
        <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
        <Table
          size="middle"
          dataSource={list}
          rowKey="id"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          // scroll={{ x: 1800 }}
          onChange={onChangeTable}
        >
          <Column title="ID" dataIndex="id" />
          <Column title="行业分组名称" dataIndex="groupName" render={(text, record) => <span style={{ color: '#168FFF', cursor: 'pointer' }} type="edit" onClick={() => onWatch(record)}>{record.groupName}</span>}/>
          <Column title="项目名称" dataIndex="project" />
          <Column title="创建时间" dataIndex="addTime" />
          <Column title="操作" key="set" width={100} render={(text, record) => (
            <>
              {
               <Auth auths={authList} code="operate">
                  <Tooltip title="编辑" placement="bottom">
                    <Icon className="operate-icon" style={{ color: '#168FFF' }} type="edit" onClick={() => onEdit(record)} />
                  </Tooltip>
                  {' '}
                  {!text.statusFlag ? <Popconfirm title="确认启用吗？" cancelText="取消" okText="确认" onConfirm={() => onToggle(record)}>
                    <Switch checkedChildren="开" size="small" unCheckedChildren="关" checked={text.statusFlag} />
                  </Popconfirm> : <Switch checkedChildren="开" size="small" unCheckedChildren="关" onChange={() => onToggle(record)} checked={text.statusFlag} />}
                </Auth>
              }
            </>
          )} />
        </Table>
        <Drawer
          title={drawerName}
          width={700}
          onClose={() => {
            setEditVisible(false)
            setWatchFlag(false)
          }}
          visible={editVisible}
          destroyOnClose={true}
        >
        <Spin spinning={false}>
          <IndDrawer
            setEditVisible={setEditVisible}
            editId={editData.id}
            editProId={editData.projectId}
            getProIndGroupsList={getProIndGroupsList}
            watchFlag={watchFlag}
          />
        </Spin>
      </Drawer>
      </>
    </>
  )
}

export default Form.create()(Industry)