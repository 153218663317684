/**
 * 模块名称: 委托书管理
 * @author xuxiaobo@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Main from './Main'
import Details from './Details'

const Authorization = ({ match, history }) => {

  return (
    <div>
      <Switch> 
        <Route path={match.path + '/details'} component={Details} />
        <Route component={Main} />
      </Switch>
    </div>
  )
}

export default Authorization