/**
 * 模块名称: 委托书详情
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Descriptions,
  Table,
  message 
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import CryptoJS from 'crypto-js'

const { Column } = Table

const AuthorizationDetail = (props) => {
  const { history } = props
  const search = parseSearch(props.location.search)
  const [detailData, setDetailData] = useState({})
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
	const [powerOfAttorneyAttachments, setPowerOfAttorneyAttachments] = useState([])
	const [payAgreementAttachments, setPayAgreementAttachments] = useState([])
  const [tableDisplay, setTableDisplay] = useState(true) //是否提示必选

  useEffect(() => {
    let plaintext =  search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      setList([{no:'', client:'', authorName:'', authorAccount:'', relation:'', weituo:'', fukuan:''}])
      setCount(1)

      api.getAuthorizationDetail({ id: search.id }, true).then(data => {
        data['weituo'] = data['isCopyPowerOfAttorneyCn'] ? data['isUpPowerOfAttorneyCn'] +'，'+ data['isCopyPowerOfAttorneyCn'] : data['isUpPowerOfAttorneyCn']
        data['fukuan'] =  data['isCopyPayAgreementCn'] ? data['isUpPayAgreementCn'] +'，'+ data['isCopyPayAgreementCn'] : data['isUpPayAgreementCn']

        try{
          data['powerOfAttorneyAttachments'] = JSON.parse(data['powerOfAttorneyAttachments'])
        }
        catch(e){}

        try{
          data['payAgreementAttachments'] = JSON.parse(data['payAgreementAttachments'])
        }
        catch(e){}

        // console.log(data)

        setPowerOfAttorneyAttachments(data['powerOfAttorneyAttachments'])
        setPayAgreementAttachments(data['payAgreementAttachments'])

        setDetailData(data)

        let tmplist = []
        tmplist.push(data)
        setList(tmplist)
        setCount(tmplist.length)
      })
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  return (
    <>
      <div className="pro-detail">
        <Descriptions title="委托书详情" layout="horizontal" className="pro-detail-title">
          <Descriptions.Item label="客户类型">
            {detailData.relationTypeCn}
          </Descriptions.Item>
          
          <Descriptions.Item label="OAID/QID">
          {detailData.relationId}
          </Descriptions.Item>

          <Descriptions.Item label="客户名称/代理商名称">
          {detailData.relationName}
          </Descriptions.Item>
        </Descriptions>
        <div className="line-box"></div>

        <Descriptions title="委托书信息" layout="horizontal" className="pro-detail-smailtitle"></Descriptions>
        <Table
          dataSource={list}
          rowKey="id"
          pagination={false}
          loading={false}
          hidden={tableDisplay}
        >
          <Column title="委托书编号" dataIndex="no" />
          <Column title="委托方" dataIndex="client" />
          <Column title="被委托方用户名" dataIndex="authorName" />
          <Column title="被委托方账号" dataIndex="authorAccount" />
          <Column title="关系" dataIndex="relation" />
          {/* <Column title="委托书" dataIndex="weituo" /> */}
          <Column title="指定付款协议" dataIndex="isUpPayAgreementCn" />
          <Column title="类型" dataIndex="mixPayAndAttorneyCn" />
        </Table>
        <div className="line-box"></div>

        {/* <Descriptions title="委托书附件" layout="horizontal" className="pro-detail-smailtitle"></Descriptions>
          <div className="content">
            {
              powerOfAttorneyAttachments.length ? powerOfAttorneyAttachments.map((item, index) => {
                return <div key={index}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
              }) : '无'
            }
          </div>
        <div className="line-box"></div> */}

        <Descriptions title="指定付款协议附件" layout="horizontal" className="pro-detail-smailtitle"></Descriptions>
          <div className="content">
            {
              payAgreementAttachments.length ? payAgreementAttachments.map((item, index) => {
                return <div key={index}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
              }) : '无'
            }
          </div>
        <div className="line-box"></div>

        <Descriptions title="备注" layout="horizontal" className="pro-detail-smailtitle"></Descriptions>
          <div className="content">{detailData.remark ? detailData.remark : '无'}</div>
        
      </div>
    </>
  )
}

export default AuthorizationDetail;