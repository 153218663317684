/**
 * 模块名称: 业务配置管理
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ProjectMgt from './ProjectMgt'
import Projectsetting from './Projectsetting'
import DiscountTable from './DiscountTable'
import './assets/style.scss'

const ConfigMsgIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/projectMgt'} component={ProjectMgt} />
      <Route path={match.path + '/projectConfig'} component={Projectsetting} />
      <Route path={match.path + '/discountTable'} component={DiscountTable} />
      <Route component={ProjectMgt} />
    </Switch>
  )
}

export default ConfigMsgIndex